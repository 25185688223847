import React from "react";
import Styled from "styled-components";
import Internships from "./InternshipsAll";
export default function index() {
  return (
    <Container>
      <Internships />
    </Container>
  );
}

const Container = Styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    @media(max-width: 480px) {
        width: 100%;
    }
`;
