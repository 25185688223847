import Styled from "styled-components";

export const InternshipDiv = Styled.div`
    padding: 4em;
    padding-bottom: 1.5em;
    @media (max-width: 480px) {
        padding: 0;
    }
    @media (min-width: 1080px) {
        width: 1080px;
        margin: 5.5em auto;
        padding: 0 4em;
    }
`;

export const InternshipContainer = Styled.section`
    @media (min-width: 1440px) {
		margin: 4em auto;
	}
    
    @media (max-width: 480px) {
        width: 100%;
        padding: 0;
	}
    margin-top: 4em;
`;

export const WrapperDiv = Styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const InternshipWrapper = Styled.main`
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    @media (max-width: 480px) {
    border: 1px solid rgba(0, 0, 0, 0.2);
	}
`;

export const RecSection = Styled.section``;

export const Container = Styled.div`
    position: relative;
    width: 100%;
`;
export const YellowContainer = Styled.section`
	height: 300px;
    position: absolute;
    z-index: -100;
    top: 5em;
    left: 0;
    width: 100%;
    padding: 1.15em;
    @media (max-width: 480px) {
        display: none;
    }
`;

export const YellowDiv = Styled.div`
    background-color: #FFF56C;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
`;
