import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <DIV>
      <Container>
        <Wrapper>
          <TitleSection>
            <P>© Setugram 2022</P>
            <P1>E-mail: contact@setugram.com</P1>
          </TitleSection>
          <ContactSection>
            <P1>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/private-policy"
              >
                Privacy Policy
              </Link>
            </P1>
            <P1>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/term-of-use"
              >
                Terms of Use
              </Link>
            </P1>
            <P1>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/refund-policy"
              >
                Refund Policy
              </Link>
            </P1>
          </ContactSection>
          <Section>
            <Title> Connect</Title>
            <a
              href="https://instagram.com/setu.gram?utm_medium=copy_link"
              target="_blank"
            >
              <GrInstagram style={{ margin: "0 1.5em 0 0", color: "#fff" }} />
            </a>
            <a
              href="https://www.facebook.com/SetuGram-106599355043033"
              target="_blank"
            >
              <FaFacebookSquare
                style={{ margin: "0 1.5em 0 0", color: "#fff" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/setugram/?viewAsMember=true"
              target="_blank"
            >
              <FaLinkedin style={{ margin: "0 1.5em 0 0", color: "#fff" }} />
            </a>
          </Section>
        </Wrapper>
      </Container>
    </DIV>
  );
}

const DIV = styled.div`
  background-color: #000;
  bottom: 0;
  left: 0px;
  right: 0px;
`;

const TitleSection = styled.section`
  height: 100%;
`;

const P = styled.p`
  line-height: 1.3em;
  font-size: 1.1em;
  font-weight: 300;
`;

const P1 = styled.p`
  line-height: 0.5em;
  font-size: 1.1em;
  font-weight: 300;
`;

const Title = styled.h5`
  font-size: 1.3em;
  font-weight: 400;
  margin-bottom: 1.2em;
  @media (max-width: 500px) {
    margin-bottom: 0.2em;
  }
`;

const Container = styled.div`
  width: 100%;
  font-size: 1em;
  background-color: #000;
  color: #fff;
  padding: 0 5em;
  @media (max-width: 480px) {
    padding: 0 3em;
    margin-top: 0;
  }
`;

const CopyrightsWrapper = styled.footer`
  border-top: 1px white solid;
  text-align: center;
  padding: 1em 5em;
  margin: 0 5em;
  color: #fff;
  background-color: #000;
  @media (max-width: 500px) {
    margin: 0 1em;
  }
`;

const ContactSection = styled.section`
  height: 100%;
  margin-left: 6 em;
  @media (max-width: 500px) {
    text-align: left;
  }
`;

const Wrapper = styled.div`
  padding: 2.5em 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    text-align: center;
    gap: 2em;
  }
`;

const Section = styled.section`
  height: 100%;
  @media (max-width: 500px) {
    text-align: left;
  }
`;
