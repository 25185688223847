import React, { useState, useEffect } from "react";
import internshipServices from "../../ApiServices/internships";
import {
  Container,
  YellowContainer,
  YellowDiv,
  InternshipContainer,
  InternshipDiv,
  WrapperDiv,
} from "./InternshipDetailsElements";
import InternshipInfo from "./InternshipInfo";
import PopularInternships from "../../Components/shared/PopularInternships";
export default function InternshipDetails({ match, InitialInternship }) {
  const [Internship, setInternships] = useState(InitialInternship);
  const id = match.params.id;
  const hook = () => {
    internshipServices
      .getInternshipById(id)
      .then((initialInternship) => {
        setInternships(initialInternship.data);
      })
      .catch((err) => {});
  };
  const [ChecklogUser, setChecklogUser] = useState(false);

  useEffect(hook, [id, ChecklogUser]);
  return (
    <Container>
      <WrapperDiv>
        <YellowContainer>
          <YellowDiv></YellowDiv>
        </YellowContainer>
        <InternshipContainer>
          <InternshipDiv>
            <InternshipInfo
              Internship={Internship}
              ChecklogUser={ChecklogUser}
              setChecklogUser={setChecklogUser}
            />
          </InternshipDiv>
          <PopularInternships title="Popular Categories" />
        </InternshipContainer>
      </WrapperDiv>
    </Container>
  );
}

InternshipDetails.defaultProps = {
  Internship: {},
};
