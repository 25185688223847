import axios from "axios";

// const url = "http://localhost:1337";

const url = process.env.REACT_APP_BASE_URL;
const getAllTopProducts = () => {
  window.scrollTo(0, 0);
  return axios
    .get(`${url}/products?_start=0&_limit=5`)
    .then((response) => response);
};

const getProductById = (id) => {
  window.scrollTo(0, 0);
  return axios.get(`${url}/products/${id}`).then((response) => response);
};

const getCategories = () => {
  return axios
    .get(`${url}/z-product-categories?_start=0&_limit=5`)
    .then((response) => response);
};

const getProductsByCategory = (
  category,
  page,
  num,
  searchtags,
  maxprice,
  homecategory,
  isActive
) => {
  window.scrollTo(0, 0);
  let query, finalquery;
  finalquery = `_start=${page}&_limit=${num}&`;

  if (isActive !== true) {
    finalquery += `isActive=true&`;
  }
  if (searchtags.length > 0) {
    query = searchtags.map((item) => {
      return `tags.name=${item}&`;
    });
  }
  if (category !== undefined) {
    finalquery += `category.name=${category}&`;
  }
  if (homecategory !== undefined) {
    finalquery += `category.name=${homecategory}&`;
  }
  if (maxprice !== undefined) {
    finalquery += `_price_lte=${maxprice}&`;
  }

  if (query !== undefined) {
    finalquery += `${query.toString().replace(/\,/g, "")}`;
  }

  return axios
    .get(`${url}/products?${finalquery}`)
    .then((response) => response);
};
//
const getTopPopular = () => {
  window.scrollTo(0, 0);
  return axios
    .get(`${url}/products?_start=0&_limit=10`)
    .then((response) => response);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllTopProducts,
  getTopPopular,
  getProductById,
  getProductsByCategory,
  getCategories,
};
