import { useState } from "react";
import { useContext, createContext } from "react";

const ItemContext = createContext();
export function useItem() {
  return useContext(ItemContext);
}

export function ItemContextProvider({ children }) {
  const [productChange, setProductChange] = useState(false);

  const changeValue = () => {
    if (productChange === false) {
      setProductChange(true);
    } else {
      setProductChange(false);
    }
  };

  const value = {
    productChange,
    changeValue,
  };

  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}
