import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import CardProductButton from "./CardProductButton";
import { MdDelete } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { useItem } from "../../Components/Context/useContext";

const url = process.env.REACT_APP_BASE_URL;

const ItemCard = ({ item, changeproduct, setTotalItem }) => {
  const { changeValue } = useItem();
  const totalItem = localStorage.getItem("TcartItem");

  const deleteProductfromCart = (orderid) => {
    axios
      .delete(`${url}/z-product-orders/${orderid}`)
      .then((response) => {
        localStorage.setItem("TcartItem", Number(totalItem) - 1);
        changeproduct();
        changeValue();
      })
      .then((response) => response);
  };

  let itemPage = "";

  if (item.product.length !== 0) {
    itemPage = item.product.id;
  }

  return (
    <Wrapper>
      {item.product && (
        <>
          <ImgDiv>
            <Link className="LinkS" to={"/products/" + itemPage}>
              <IMG src={`${item.variation.image.url}`} alt="product-img" />
            </Link>
            {/* <img src={`${url}${item.variation.image.url}`} alt="product-img"></img> */}
          </ImgDiv>
          <Info>
            <Title>{item.product.name}</Title>
            <ItemInfo
              style={{
                height: "3rem",
                margin: "5px 0 10px",
                overflow: "hidden",
              }}
            >
              <ReactMarkdown>{item.product.description}</ReactMarkdown>
            </ItemInfo>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Price>Rs.{item.product.price}</Price>
              <ButtonDiv>
                <CardProductButton
                  productid={item.id}
                  productvariant={item.variation}
                  setTotalItem={setTotalItem}
                />
              </ButtonDiv>
            </div>
            <MdDelete
              style={{
                top: 2,
                right: 2,
                position: "absolute",
                cursor: "pointer",
                height: "2rem",
                width: "2rem",
              }}
              onClick={() => {
                deleteProductfromCart(item.id);
              }}
            />
          </Info>
        </>
      )}
    </Wrapper>
  );
};

const IMG = styled.img`
  object-fit: contain;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ButtonDiv = styled.div`
  padding: 1em 2em 0;
  @media (max-width: 480px) {
    padding: 1em;
  }
`;

const ItemInfo = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.51);
  opacity: 0.8;
  @media (min-width: 480px) {
    font-size: 21px;
    line-height: 25px;
  }
`;

const Price = styled.h5`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  @media (min-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 21px;
  line-height: 19px;
  @media (min-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Info = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ImgDiv = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 200px;
  height: 85px;
  overflow: hidden;
  @media (min-width: 480px) {
    width: 264px;
    height: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 150px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 0.8em 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  gap: 10px;
  @media (min-width: 480px) {
    width: 936px;
    height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    padding: 1em;
    gap: 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    padding: 1em;
    gap: 20px;
    flex-direction: column;
  }
`;

export default ItemCard;
