import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
const url = process.env.REACT_APP_BASE_URL;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function FeaturedCourse() {
  const [featuredproduct, setFeaturedProduct] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/courses?isFeatured=true`)
      .then((response) => {
        setFeaturedProduct(response.data);
      })
      .then((response) => response);
  }, []);

  return (
    <Wrapper>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={10000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
      >
        {featuredproduct.length > 0 &&
          featuredproduct.map((data, index) => {
            return (
              <LinkS to={"/courses/" + data.id}>
                <CourseWrapper key={index}>
                  <Image>
                    <ImageWrapper
                      src={`${data.images[0].url}`}
                      alt="product-img"
                    />
                  </Image>
                  <Info>
                    <InfoTitle>{data.name}</InfoTitle>
                    <InfoInfo style={{ height: "3rem", overflow: "hidden" }}>
                      {data.description}
                    </InfoInfo>
                    <Price>Rs. {data.price}</Price>
                  </Info>
                </CourseWrapper>
              </LinkS>
            );
          })}
      </Carousel>
      <CategoryList></CategoryList>
    </Wrapper>
  );
}

const CourseWrapper = styled.div`
  height: 252px;
  margin-top: 1em;
  background-color: #fff56c;
  width: 100%;
  padding: 1.5em 2em;
  gap: 2em;
  display: flex;
  @media (max-width: 480px) {
    padding: 0.5em;
    gap: 0.5em;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  margin-top: 2.5em;
  @media (max-width: 480px) {
    margin-top: 2em;
  }
`;

const CategoryList = styled.div`
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;
  align-content: center;
  @media (max-width: 480px) {
    margin-top: 1.5em;
    overflow-x: scroll;
    gap: 1em;
  }
`;

const ImageWrapper = styled.img`
  height: 100%;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
`;

const InfoTitle = styled.h5`
  font-size: 2em;
  font-weight: 400;
  @media (max-width: 480px) {
    font-size: 21px;
  }
`;

const InfoInfo = styled.p`
  color: rgba(0, 0, 0, 0.51);
  font-size: 1.3em;
  width: 100%;
  margin-top: 0.3em;
  @media (max-width: 480px) {
    height: 3.5rem !important;
    width: 100%;
    font-size: 15px;
  }
`;
const Price = styled.p`
  position: absolute;
  bottom: 0;
  font-size: 1.4em;
  @media (max-width: 480px) {
    position: relative;
    font-size: 17px;
  }
`;
const Image = styled.div`
  flex: 1;
  background-color: #ffffff;
  @media (max-width: 480px) {
    width: 154.1px;
    height: 145px;
  }
`;
const Info = styled.div`
  flex: 3.6;
  position: relative;
  @media (max-width: 480px) {
    flex: 1;
  }
`;
const LinkS = styled(Link)`
  text-decoration: none;
  color: #000;
`;
