import styled from "styled-components";
import React from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { useItem } from "../../Components/Context/useContext";
const url = process.env.REACT_APP_BASE_URL;

const CourseItemCard = ({ item, changecourse }) => {
  const { changeValue } = useItem();
  const totalItem = localStorage.getItem("TcartItem");

  const deleteCoursefromCart = (orderid) => {
    axios
      .delete(`${url}/z-course-orders/${orderid}`)
      .then((response) => {
        localStorage.setItem("TcartItem", Number(totalItem) - 1);
        changecourse();
        changeValue();
      })
      .then((response) => response);
  };
  let courseDetailPage = "";

  if (item.course.length !== 0) {
    courseDetailPage = item.course.id;
  }
  return (
    <Wrapper>
      {item.course && (
        <>
          <ImgDiv>
            <Link className="LinkS" to={"/courses/" + courseDetailPage}>
              <IMG src={`${item.course.images[0].url}`} alt="courses-img" />
            </Link>
          </ImgDiv>
          <Info>
            <OrderName>
              <Title>{item.course.name}</Title>
              <ItemInfo
                style={{
                  height: "3rem",
                  margin: "5px 0 10px",
                  overflow: "hidden",
                }}
              >
                <ReactMarkdown>{item.course.description}</ReactMarkdown>
              </ItemInfo>
            </OrderName>
            <Price>Rs.{item.course.price}</Price>
          </Info>
          <MdDelete
            style={{
              top: 2,
              right: 2,
              position: "absolute",
              cursor: "pointer",
              height: "2rem",
              width: "2rem",
            }}
            onClick={() => {
              deleteCoursefromCart(item.id);
            }}
          />
        </>
      )}
    </Wrapper>
  );
};
const IMG = styled.img`
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const OrderName = styled.div`
  /* display: flex;
   justify-content: space-between; */
  @media (max-width: 480px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
`;
const ItemInfo = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.51);
  opacity: 0.8;
  @media (min-width: 480px) {
    font-size: 21px;
    line-height: 25px;
  }
`;

const Price = styled.h5`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  @media (min-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 21px;
  line-height: 19px;
  @media (min-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Info = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ImgDiv = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 200px;
  height: 85px;
  overflow: hidden;
  @media (min-width: 480px) {
    width: 264px;
    height: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 150px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 0.8em 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  gap: 10px;
  @media (min-width: 480px) {
    width: 936px;
    height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    padding: 1em;
    gap: 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    padding: 1em;
    gap: 20px;
    flex-direction: column;
  }
`;

export default CourseItemCard;
