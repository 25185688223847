import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ReactMarkdown from "react-markdown";
import { Title, Info } from "./CourseDetailsElements";
Modal.setAppElement("#root");
export default function Readmore(props) {
  const { ModalIsOpen, setModalIsOpen, name, data } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      overflow: "visible",
      width: window.innerWidth > 480 ? "40%" : "90%",
      height: "30rem",
      overflow: "scroll",
      marginTop: "2rem",
      transform: "translate(-50%, -50%)",
      padding: 20,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <Modal
      isOpen={ModalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <div>
        <Title style={{ marginBottom: "3rem" }}>{name}</Title>
        <ReactMarkdown>{data}</ReactMarkdown>
      </div>
    </Modal>
  );
}

const Sbutton = styled.button`
  outline: none;
  color: #004;
  background-color: #fff;
  font-size: 16px;
  border: none;
  &:hover {
    color: #00a;
  }
`;

const ModalS = styled(Modal)`
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	overflow: 'visible',
	width: window.innerWidth>480?'40%':'90%',
	marginTop: "2rem",
	transform: 'translate(-50%, -50%)',
	padding: 20,
	position: fixed;
	@media (max-width: 480px) {
		font-size: 12px;
		width: 280px;
		max-height: 350px;
		min-height: 350px;
		margin: 0;
		padding: 2em;
	}
`;

const Form = styled.form`
  text-align: center;
  z-index: 1000;
  @media (max-width: 480px) {
  }
`;

const NewAccountWrapper = styled.p`
  margin-top: 1em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 2em;
`;

const ButtonWrapper = styled.div``;

const ForgetPasswordWrapper = styled.div`
  text-align: right;
  margin-top: 0.3em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 1em;
  margin-top: 0.8em;
  border-radius: 10px;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 0.2em;
  }
`;

const InputLabel = styled.label`
  font-size: 1.3em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 1.5em;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.9em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
