import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Styled from "styled-components";
import ItemCard from "./ItemCard";
import CourseItemCard from "./CourseItemCard";
import axios from "axios";
import logo_setugram from "../../assets/images/logo_strapi.png";
import AddressPopup from "../Profile/AddressPopup";
import { UseSetAddress } from "../../Components/context";
import { BeatLoader } from "react-spinners";
const Aurl = process.env.REACT_APP_LAMDA_URL;

const url = process.env.REACT_APP_BASE_URL;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default function Items({ setProduct, setCourses }) {
  const USER_ID = localStorage.getItem("setugramuser");
  const EMAIL = localStorage.getItem("setugramuserEmailid");
  const [getProductOrder, setgetProductOrder] = useState([]);
  const [getCoursesOrder, setgetCoursesOrder] = useState([]);
  const [deleteproduct, setDeleteProduct] = useState(false);
  const [deletecourse, setDeleteCourse] = useState(false);
  const [totalitem, setTotalItem] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [setname, setSetName] = useState("");
  const [setemail, setSetEmail] = useState("");
  const [setcontact, setSetContact] = useState("");

  const { AddressC, setAddressC } = UseSetAddress();

  const [applynowisopen, setApplyNowIsOpen] = useState(false);

  const handleClose = () => {
    setApplyNowIsOpen(false);
  };

  var totalAmount = 0;

  useEffect(() => {
    fetch(`${url}/users/${USER_ID}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getdetails) => {
        setAddressC(getdetails.address);
        setSetName(getdetails.name);
        setSetEmail(getdetails.email);
        setSetContact(getdetails.contact);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [AddressC]);

  const changeInc = () => {
    if (totalitem === true) {
      setTotalItem(false);
    } else {
      setTotalItem(true);
    }
  };

  const changeproduct = () => {
    if (deleteproduct === true) {
      setDeleteProduct(false);
    } else {
      setDeleteProduct(true);
    }
  };

  const changecourse = () => {
    if (deletecourse === true) {
      setDeleteCourse(false);
    } else {
      setDeleteCourse(true);
    }
  };

  var productIds = [];
  var coursesIds = [];

  useEffect(() => {
    fetch(`${url}/z-product-orders?buyers.id=${USER_ID}&_status=InCart`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getproduct) => {
        setgetProductOrder(getproduct);
        setProduct(getproduct.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deleteproduct, totalitem]);

  useEffect(() => {
    fetch(`${url}/z-course-orders?users.id=${USER_ID}&_status=InCart`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getproduct) => {
        setgetCoursesOrder(getproduct);
        setCourses(getproduct.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deletecourse, totalitem]);

  const sendMail = () => {
    fetch(`${Aurl}/default/sendContactForm`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        receiverMail: EMAIL,
        subject: "Congratulations! Your Order has been Placed",
        receiverName: "admin",
        message: `Hi Applicant!

Congratulations!				
You’ve completed registration for Course on setugram. Your registration is confirmed.
You will soon receive an email with all the details regarding the Session.
Please don’t hesitate to contact us at contact@setugram.com with any questions or concerns, if you have any.

All the best,

Team SetuGram`,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
      });
  };

  for (var i = 0; i < getProductOrder.length; i++) {
    totalAmount +=
      getProductOrder[i].quantity * Number(getProductOrder[i].product.price);
    productIds.push(getProductOrder[i].id);
  }

  for (var j = 0; j < getCoursesOrder.length; j++) {
    totalAmount += Number(getCoursesOrder[j].course.price);
    coursesIds.push(getCoursesOrder[j].id);
  }

  let history = useHistory();
  let location = useLocation();
  let { fail } = location.state || { fail: { pathname: "/payment_failure" } };
  let { succ } = location.state || {
    succ: { pathname: "/payment_successfull" },
  };

  const responsePage = () => {
    history.replace(fail);
  };

  const createOrderId = async () => {
    localStorage.setItem("final_total", totalAmount);
    if (!AddressC) {
      alert("Please Add your address");

      setApplyNowIsOpen(true);
    } else {
      setIsActive(true);
      await axios
        .post(`https://lk901kxxt5.execute-api.us-east-1.amazonaws.com/order`, {
          amount: totalAmount * 100,
        })
        .then((response) => {
          displayRazorpay(response.data.id);
        })
        .catch((Error) => {
          console.log(Error);
        });
    }
  };

  const displayRazorpay = async (id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_live_t5FjWN3oimCcVu",
      currency: "INR",
      order_id: id,
      image: logo_setugram,
      name: setname,
      handler: function (response) {
        addTransaction(response.razorpay_payment_id);
      },
      prefill: {
        name: setname,
        email: setemail,
        contact: setcontact,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      responsePage();
    });

    paymentObject.open();
  };

  const addTransaction = async (transactionid) => {
    await axios
      .post(`${url}/transactions`, {
        transactionId: transactionid,
        amount: totalAmount,
        user: USER_ID,
        product_orders: productIds,
        course_orders: coursesIds,
      })
      .then(async (response) => {
        await UpdateOrder();
        sendMail();
      })
      .catch((Error) => {});
  };

  const UpdateOrder = () => {
    productIds.map(async (item) => {
      await axios
        .put(`${url}/z-product-orders/${item}`, {
          status: "Ordered",
        })
        .then((response) => {
          changeInc();
          setIsActive(false);
        })
        .catch((Error) => {});
    });

    coursesIds.map(async (item) => {
      await axios
        .put(`${url}/z-course-orders/${item}`, {
          status: "Ordered",
        })
        .then((response) => {
          changeInc();
        })
        .catch((Error) => {});
    });

    history.replace(succ);
  };

  return (
    <Wrapper>
      <ItemWrapper>
        {getProductOrder.length > 0 &&
          getProductOrder.map((item, index) => (
            <ItemCard
              key={index}
              item={item}
              setTotalItem={changeInc}
              changeproduct={changeproduct}
            />
          ))}
        {getCoursesOrder.length > 0 &&
          getCoursesOrder.map((item, index) => (
            <CourseItemCard
              key={index}
              item={item}
              changecourse={changecourse}
            />
          ))}
      </ItemWrapper>
      <Total>
        <Title>Total</Title>
        <Title1>Rs. {totalAmount}</Title1>
        {isActive === false && totalAmount > 0 && (
          <ButtonAdd2 Name="Checkout" onClick={() => createOrderId()}>
            Checkout
          </ButtonAdd2>
        )}
        {isActive === true && totalAmount > 0 && (
          <ButtonAdd2 Name="Checkout">
            <BeatLoader color="#fff" />
          </ButtonAdd2>
        )}
      </Total>
      <AddressPopup
        addAddressIsOpenAD={applynowisopen}
        handleCloseAD={handleClose}
      ></AddressPopup>
    </Wrapper>
  );
}

const Title = Styled.h5`
    font-size: 21px;
    font-weight: 400;
`;

const Title1 = Styled.h5`
    margin-top: 1rem;
    font-size: 40px;
    font-weight: 400;
	@media (max-width:480px) {
    margin: 0;
    }
`;

const ButtonAdd2 = Styled.div`
display: flex;
cursor: pointer;
padding: 0.5em 1em;
margin-top: 8rem;
align-items: center;
outline: none;
border: none;
justify-content: center;
font-weight: bold;
border-radius: 10px;
font-size: 1.2em;
background-color: #000;
color: #fff;
bottom: 0;

@media (max-width:480px) {
    margin: 0;
    }
`;

const ItemWrapper = Styled.section``;

const Total = Styled.section`
    padding: 1em;
    height: 150px;
    @media (min-width:480px) {
        width: 338px;
        height: 310px;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
`;

const Wrapper = Styled.div`
    @media (min-width: 480px) {
        display: flex;
        padding: 2em 3em 1em 1em;
        gap: 20px;
    }
    @media (min-width: 1440px) {
        width: 1440px;
        margin: 0 auto;
        padding-left: 5em;
    }
`;
