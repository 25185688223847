import React from "react";
import Styled from "styled-components";
import Courses from "./Courses";

export default function CoursesPage() {
  return (
    <Container>
      <TitleWrapper>
        <Title>Your Courses</Title>
      </TitleWrapper>
      <Courses />
    </Container>
  );
}

const TitleWrapper = Styled.div`
    background-color: #FFF56C;
    height: 102px;
    padding: 1.7em 2em;
    padding-top: 3rem;
    @media (max-width: 480px) {
        height: 74px;
        padding-top: 40px;
        padding-left: .5em;
        padding-bottom: 1.5em;
    }
`;

const Container = Styled.div`
    margin-top: 4em;
    @media (max-width: 480px) {
        padding: 1em;
    }
`;

const Title = Styled.h1`
    font-size: 2em;
    font-weight: 500;
    @media (max-width: 480px) {
        font-size: 21px;
    }
`;
