import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import OrderCourse from "./OrderCourse";
const url = process.env.REACT_APP_BASE_URL;

export default function Courses() {
  const USER_ID = localStorage.getItem("setugramuser");
  const [getCoursesOrder, setgetCoursesOrder] = useState();

  useEffect(() => {
    fetch(`${url}/z-course-orders?users.id=${USER_ID}&_status_ne=InCart`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getproduct) => {
        setgetCoursesOrder(getproduct);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Wrapper>
      <ItemWrapper>
        {getCoursesOrder !== undefined &&
          getCoursesOrder.map((item, index) => (
            <OrderCourse key={index} item={item} />
          ))}
      </ItemWrapper>
    </Wrapper>
  );
}

const Title = Styled.h5`
    font-size: 21px;
    font-weight: 400;
`;

const ItemWrapper = Styled.section`
   min-height: 40vh;
`;

const Total = Styled.section`
    padding: 1em;

    @media (min-width:480px) {
        width: 338px;
        height: 310px;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
`;

const Wrapper = Styled.div`
    height: 80vh;
    @media (min-width: 480px) {
        display: flex;
        padding: 2em 3em 1em 1em;
        gap: 20px;
    }
    @media (min-width: 1440px) {
        width: 1440px;
        margin: 0 auto;
        padding-left: 5em;
    }
`;
