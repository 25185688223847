import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

const getCategories = () => {
  return axios
    .get(`${url}/course-categories?_start=0&`)
    .then((response) => response);
};

const getAllTopCourses = (
  page,
  num,
  searchtags,
  duration,
  category,
  maxprice,
  homecategory,
  isActive
) => {
  window.scrollTo(0, 0);
  let query, finalquery;
  finalquery = `_start=${page}&_limit=${num}&`;
  if (isActive !== true) {
    finalquery += `isActive=true&`;
  }
  if (searchtags.length > 0) {
    query = searchtags.map((item) => {
      return `tags.name=${item}&`;
    });
  }

  if (duration !== undefined) {
    finalquery += `duration.Months=${duration}&`;
  }

  if (category !== undefined) {
    finalquery += `category.name=${category}&`;
  }
  if (homecategory !== undefined) {
    finalquery += `category.name=${homecategory}&`;
  }
  if (maxprice != undefined) {
    finalquery += `_price_lte=${maxprice}&`;
  }

  if (query !== undefined) {
    finalquery += `${query.toString().replace(/\,/g, "")}`;
  }
  //
  return axios.get(`${url}/courses?${finalquery}`).then((response) => response);
};

const getCoursesHome = () => {
  window.scrollTo(0, 0);
  let query = `_start=0&_limit=4`;
  return axios.get(`${url}/courses?${query}`).then((response) => response);
};

const getAllPopularCourses = () => {
  window.scrollTo(0, 0);
  return axios
    .get(`${url}/courses?_start=0&_limit=5`)
    .then((response) => response);
};

const getCourseById = (id) => {
  window.scrollTo(0, 0);
  return axios.get(`${url}/courses/${id}`).then((response) => response);
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllTopCourses,
  getCourseById,
  getAllPopularCourses,
  getCoursesHome,
  getCategories,
};
