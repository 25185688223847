import React from "react";
import { FaBars } from "react-icons/fa";
import styled from "styled-components";
export default function Toggle({ handleNavToggle }) {
  return <StyledToggle onClick={handleNavToggle} />;
}

const StyledToggle = styled(FaBars)`
  position: fixed;
  top: 10px;
  right: 10px;
  color: #fff;
  background: #000;
  padding: 5px;
  display: flex;
  place-items: center;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 5px;
  @media (max-width: 480px) {
    margin: 0.2em 0 0;
  }
`;
