import Styled from "styled-components";
export const Wrapper = Styled.section`
    Width: 336px;
    Height: 410px;
	box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.44);
-webkit-box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.44);
-moz-box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.44);
	border-radius: 10px;
    flex: 1;
    @media (max-width: 480px) {
        display: none;
    }
`;
