import React from "react";
import styled from "styled-components";

export default function ButtonAdd({ Name, bg_color, ft_color, handleClick }) {
  return (
    <ButtonDiv bg_color={bg_color} ft_color={ft_color} onClick={handleClick}>
      {Name}
    </ButtonDiv>
  );
}

const ButtonDiv = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  display: flex;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  cursor: pointer;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  background-color: #000000;
`;
