import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LocationIndex from "../../../../Components/Card/Location";
import Locations from "../../../../assets/images/location.svg";
export default function Location() {
  const title = "Locations";
  const [locations, setLocations] = useState([
    "Mumbai",
    "Delhi",
    "Pune",
    "Kolkata",
    "Bengaluru",
  ]);

  return (
    <Wrapper>
      <TitleDiv2>
        <H4>{title}</H4>
      </TitleDiv2>
      <CategoryList>
        {locations.map((Category, index) => (
          <LocationIndex
            key={index}
            CardTitle={Category}
            link="#"
            imageLink={Locations}
          />
        ))}
      </CategoryList>
    </Wrapper>
  );
}

const H4 = styled.h4`
  font-size: 1.4em;
  font-weight: normal;
`;

const TitleDiv2 = styled.div`
  align-items: center;
  justify-content: space-between;
`;

const CategoryList = styled.div`
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;
  align-content: center;
  @media (max-width: 480px) {
    margin-top: 1.5em;
    overflow-x: scroll;
    gap: 1em;
  }
`;

const Wrapper = styled.div`
  margin-top: 2.5em;
`;

const LocationList = styled.div`
  margin-top: 2.5em;
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  align-content: center;
  gap: 1em;
  @media (max-width: 480px) {
    margin-top: 1em;
    overflow-x: scroll;
  }
`;
