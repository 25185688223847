import Styled from "styled-components";
import { Link } from "react-router-dom";
export const Section = Styled.section`
    padding: 15px;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`;
export const CompanyName = Styled.p`
    font-size: 21px;
    line-height: 1.5em;
    color: rgba(0, 0, 0, 0.51);
    font-weight: 500 !important;
    @media (max-width: 480px) {
        font-size: 14px;
        line-height: 17px;
    }
`;
export const DetailTitle = Styled.h5`
    font-size: 21px;
    line-height: 1.4em;
    font-weight: 500 !important;
    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 14px;
    }
`;
export const DetailInfo = Styled.p`
    color: rgba(0, 0, 0, 0.5);font-size: 1.3em;
    margin-top: .5em;
    line-height: 1.4em;
    font-weight: 500 !important;
    @media (max-width: 480px) {
        font-size: 12px;
    line-height: 14px;
    }
`;
export const SkillsSection = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;
    gap: 1em;
`;
export const ViewDetailsSection = Styled.p`
    text-align: right;
    margin: 1em 0.4em ;
    line-height: 1.4em;
    font-size: 1em;
    font-weight: 500;    
`;
export const LinkS = Styled(Link)`
	cursor: pointer;
	text-decoration: none;
    color: #000;
    height: 100%;
`;
export const SkillButton = Styled.section`
    background-color: #FFF56C;
    border-radius: 5px;
    text-align: center;
    font-size: 1.05em;
    font-weight: 500;
    flex: auto;
    padding: 10px;
    @media (max-width: 480px) {
        font-size: 12px;
        padding: 5px;
    }
`;
export const DetailSection = Styled.section`

`;
export const Details = Styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
    margin-top: 1.5em;
`;
export const Title = Styled.h3`
    padding: 9px 15px;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
    background-color: #FFF56C;
    a {
        text-decoration: none;
        color: black;
    }
    @media (max-width: 480px) {
        font-size: 14px;
        line-height: 17px;
    }
`;
export const Wrapper = Styled.section`
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    @media (max-width: 480px) {
        font-size: 14px;
        width: auto;
    }
`;
