import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

export const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledLinkA = styled.a`
  text-decoration: none;
  font-size: clamp(40px, 4vw, 6vw);
  transition: 0.2s all ease-in-out;
  color: #000;
  &:hover,
  &:focus {
    transition: 0.2s all ease-in-out;
    color: red;
  }
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: clamp(40px, 4vw, 6vw);
  margin-top: 3rem;
  transition: 0.2s all ease-in-out;
  color: #000;
  &:hover,
  &:focus {
    transition: 0.2s all ease-in-out;
    color: red;
  }
`;

export const ClosedToggle = styled(FaTimes)`
  position: fixed;
  top: 10px;
  right: 10px;
  background: #000;
  padding: 5px;
  color: #fff;
  display: flex;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 20px;
`;
