import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InternshipCard from "../../pages/Internships/InternshipCard";
import Carousel from "react-elastic-carousel";
import InternshipServices from "../../ApiServices/internships";
import "../../../src/App.css";
export default function PopularInternships({ title }) {
  const [internships, setInternships] = useState([]);
  const fetch = () => {
    InternshipServices.getInternshipRecm()
      .then((initialInternship) => {
        setInternships(initialInternship.data);
      })
      .catch((err) => {});
  };
  useEffect(fetch, []);
  let toDisplay;
  if (title === "none") {
    toDisplay = "none";
  }
  const setting = {
    breakPoints: [
      { width: 550, itemsToShow: 1, itemsToScroll: 1, pagination: false },
      { width: 850, itemsToShow: 2, itemsToScroll: 1, pagination: true },
    ],
    itemPadding: [0, 10],
  };
  return (
    <Container>
      <TitleWrapper toDisplay={toDisplay}>
        <Title>{title}</Title>
        <HorizontalLine></HorizontalLine>
      </TitleWrapper>
      <Wrapper className="styling-example">
        <Carousel {...setting}>
          {internships.map((internship, index) => (
            <InternshipCard key={index} internship={internship} />
          ))}
        </Carousel>
      </Wrapper>
    </Container>
  );
}

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  display: ${(props) => props.toDisplay};
`;

const HorizontalLine = styled.div`
  border-top: 1.5px solid rgba(0, 0, 0, 0.2);
  flex: 1;
`;

PopularInternships.defaultProps = {
  title: "Recommendation",
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2em;
  @media (max-width: 480px) {
    margin-top: 1em;
  }
`;

const Title = styled.h4`
  font-size: 1.5em;
  padding-left: 0.5em;
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Container = styled.div`
  margin-top: 2em;
  box-sizing: border-box;
  margin-bottom: 1em;
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 2em auto;
  }
`;
