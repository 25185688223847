import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const url = process.env.REACT_APP_BASE_URL;

export default function Search({ setSearchTags }) {
  const location = useLocation();
  const history = useHistory();
  const [coursestags, setCoursesTags] = useState([]);
  const [choosentag, setChoosenTag] = useState([]);
  const [choosenArray, setChoosenArray] = useState([]);
  const [internshiptag, setInternshipTag] = useState([]);
  const [producttag, setProductTag] = useState([]);
  const [internshipArray, setInternshipArray] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [pickoption, setPickOption] = useState("Internship");

  useEffect(() => {
    if (pickoption === "Internship") {
      fetch(`${url}/z-tags-internships`, {
        method: "get",
      })
        .then((res) => res.json())
        .then((data) => {
          setCoursesTags(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (pickoption === "Products") {
      fetch(`${url}/z-tags-products`, {
        method: "get",
      })
        .then((res) => res.json())
        .then((data) => {
          setCoursesTags(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (pickoption === "Courses") {
      fetch(`${url}/z-tags-courses`, {
        method: "get",
      })
        .then((res) => res.json())
        .then((data) => {
          setCoursesTags(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [pickoption]);

  const options = ["Career", "Products", "Courses"];
  const defaultOption = options[0];

  return (
    <Wrapper>
      <Dropdown
        options={options}
        value={defaultOption}
        onChange={(e) => setPickOption(e.value)}
        style={{ outerHeight: "20px" }}
        placeholder="Select an option"
      />
      {pickoption === "Internship" && (
        <Autocomplete
          id="myInput"
          multiple
          style={{ width: "100%" }}
          id="tags-outlined"
          onKeyPress={(event) => {
            localStorage.setItem(
              "internshipchoose",
              JSON.stringify(internshiptag)
            );
            localStorage.setItem(
              "internshiparray",
              JSON.stringify(internshipArray)
            );
            history.push("/internships");
          }}
          options={coursestags}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={(event, newValue) => {
            setInternshipTag(
              newValue.map((ele) => {
                return ele.name;
              })
            );
            setInternshipArray(
              newValue.map((ele) => {
                return ele;
              })
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search Internships/jobs"
            />
          )}
        />
      )}
      {pickoption === "Products" && (
        <Autocomplete
          id="myInput"
          multiple
          style={{ width: "100%" }}
          id="tags-outlined"
          onKeyPress={(event) => {
            localStorage.setItem("productchoose", JSON.stringify(producttag));
            localStorage.setItem("productarray", JSON.stringify(productArray));
            history.push("/products");
          }}
          options={coursestags}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={(event, newValue) => {
            setProductTag(
              newValue.map((ele) => {
                return ele.name;
              })
            );
            setProductArray(
              newValue.map((ele) => {
                return ele;
              })
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Search Products"
              placeholder="Search Products"
            />
          )}
        />
      )}
      {pickoption === "Courses" && (
        <Autocomplete
          id="myInput"
          multiple
          style={{ width: "100%" }}
          id="tags-outlined"
          onKeyPress={(event) => {
            localStorage.setItem("choosechoose", JSON.stringify(choosentag));
            localStorage.setItem("choosearrray", JSON.stringify(choosenArray));
            history.push("/courses");
          }}
          options={coursestags}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={(event, newValue) => {
            setChoosenTag(
              newValue.map((ele) => {
                return ele.name;
              })
            );
            setChoosenArray(
              newValue.map((ele) => {
                return ele;
              })
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Search Courses"
              placeholder="Search Courses"
            />
          )}
        />
      )}
    </Wrapper>
  );
}

const ResultWrapperTest = styled.div`
  background-color: none;
  place-content: center;
  position: relative;
`;

const Wrapper = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;
  display: flex;
  @media (max-width: 480px) {
    width: 95%;
  }
`;

const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  width: 100%;
  border-radius: 30px;
  padding: 0.5em;
  font-size: 1.2em;
  @media (max-width: 480px) {
    padding: 0.2em;
    width: 80%;
    margin-left: 0.5em;
  }
`;
