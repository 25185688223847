import React from "react";
import { StyledLink, StyledMenu, ClosedToggle } from "./indexElements";
export default function index({ handleNavToggle }) {
  return (
    <StyledMenu>
      <StyledLink to="/" onClick={handleNavToggle} exact>
        Home
      </StyledLink>
      <StyledLink to="/internships" onClick={handleNavToggle} exact>
        Internships
      </StyledLink>
      <StyledLink to="/products" onClick={handleNavToggle} exact>
        Products
      </StyledLink>
      <StyledLink to="/courses" onClick={handleNavToggle} exact>
        Courses
      </StyledLink>
      <StyledLink to="/#about" onClick={handleNavToggle} exact>
        About
      </StyledLink>
      <StyledLink to="/#contact" onClick={handleNavToggle} exact>
        Contact
      </StyledLink>
      <StyledLink
        href="https://employer.setugram.com/"
        onClick={handleNavToggle}
        exact
      >
        Employer
      </StyledLink>
      <ClosedToggle onClick={handleNavToggle} />
    </StyledMenu>
  );
}
