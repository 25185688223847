import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const url = process.env.REACT_APP_BASE_URL;

export default function EcommerceSearchBar({ setSearchTags }) {
  const [ecommercetags, setEcommerceTags] = useState([]);
  const [choosentag, setChoosenTag] = useState([]);

  setSearchTags(choosentag);

  useEffect(() => {
    fetch(`${url}/z-tags-products`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setEcommerceTags(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Wrapper>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={ecommercetags}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        onChange={(event, newValue) => {
          setChoosenTag(
            newValue.map((ele) => {
              return ele.name;
            })
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Search ..."
            placeholder="Favorites"
          />
        )}
      />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  /* display: grid; */
  place-content: center;
  position: relative;
  padding: 0 0 30px;
`;
