import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import S3 from "react-aws-s3";
import { Config } from "./ProfileAWS";
import Button from "../../Components/Button";

const ReactS3Client = new S3(Config);
const url = process.env.REACT_APP_BASE_URL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "visible",
    width: "80%",
    marginTop: "2rem",
    transform: "translate(-50%, -50%)",
    paddingTop: 50,
    paddingBottom: 50,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const Portfolio = ({ addAddressIsOpen3, handleClose3 }) => {
  const [files, setFiles] = useState("");
  const USER_ID = localStorage.getItem("setugramuser");
  const [uploadProgress, setUploadProgess] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const uploadImage = async () => {
    if (files.type === "application/pdf") {
      if (files.size < 5242900) {
        setUploadProgess(true);
        ReactS3Client.uploadFile(files, files.name).then((data) => {
          axios
            .put(`${url}/users/${USER_ID}`, {
              portfolioS3Url: data?.location,
            })
            .then((response) => {
              setUploadProgess(false);
              handleClose3();
              Swal.fire("Done", "Successfully uploaded!", "success");
            })
            .catch((error) => {
              setUploadProgess(false);
              Swal.fire("Failed", "There is something problem!", "error");
            });
        });
      } else {
        alert("Incorrect File Size");
      }
    } else {
      alert("Incorrect File Type");
    }
  };

  return (
    <Modal
      isOpen={addAddressIsOpen3}
      onRequestClose={handleClose3}
      style={customStyles}
      ariaHideApp={false}
    >
      <span
        className="modal-close hover-pointer"
        onClick={handleClose3}
        style={{ cursor: "pointer" }}
      ></span>
      <div style={{ textAlign: "center" }}>
        <h3 className="ProfileIH3">Upload New Portfolio</h3>
      </div>
      <div style={{ width: "100%" }}>
        <label htmlFor="fInput">Upload Portfolio (pdf)</label>
        <br />
        <input
          id="textarea"
          type="file"
          onChange={(e) => setFiles(e.target.files[0])}
          required
        ></input>
        <br />
      </div>
      {uploadProgress == false && (
        <div onClick={() => uploadImage()}>
          <Button style={{ width: "100%" }} Name="Upload" />
        </div>
      )}
      {uploadProgress == true && (
        <>
          {/* <div>{uploadPercentage}% uploaded</div> */}
          <div>
            <Button
              style={{ width: "100%" }}
              Name={<BeatLoader color="#000" />}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default Portfolio;
