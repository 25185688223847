import styled from "styled-components";
import React from "react";
import PopularECommerce from "../../../Components/shared/PopularItemList";
import ProductInfo from "./ProductInfo";
export default function ProductDetails({ match }) {
  return (
    <Container>
      <YellowContainer>
        <YellowDiv></YellowDiv>
      </YellowContainer>
      <ProductInfo id={match.params.id} />
      <PopularECommerce title="Popular Categories" />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;
const YellowContainer = styled.section`
  width: 100%;
  height: 300px;
  padding: 0.7em;
  position: absolute;
  z-index: -10;
  top: 5em;
  @media (max-width: 480px) {
    display: none;
    position: relative;
  }
`;
const YellowDiv = styled.div`
  background-color: #fff56c;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;
