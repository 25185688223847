import React from "react";
import styled from "styled-components";
import logo_setugram from "../assets/images/logo_strapi.png";
import { Link } from "react-router-dom";
export default function logo() {
  return (
    <ImageDiv>
      <Link to="/">
        <IMG src={logo_setugram} alt="Setugram logo"></IMG>
      </Link>
    </ImageDiv>
  );
}

const ImageDiv = styled.div`
  margin: auto;
  width: 80px;
  height: 80px;
  margin-left: 0.5rem;
  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
    margin: 0.2em 0 0 0.2em;
  }
`;

const IMG = styled.img`
  max-height: 100%;
`;
