import React, { useState } from "react";
import styled from "styled-components";
const url = process.env.REACT_APP_BASE_URL;
const Aurl = process.env.REACT_APP_LAMDA_URL;

export default function MessageForm() {
  const [fullName, setFullName] = useState("");
  const [eMail, setEMail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [x, setX] = useState("");
  const [errors, setErrors] = useState(false);

  function handleSubjectChange(event) {
    setSubject(event.target.value);
    setX("Via Setugram Contact Form: " + event.target.value);
    setErrors(false);
  }
  function handleFullNameChange(event) {
    setFullName(event.target.value);
    setErrors(false);
  }
  function handleEMailChange(event) {
    setEMail(event.target.value);
    setErrors(false);
  }
  function handleMessageChange(event) {
    setMessage(event.target.value);
    setErrors(false);
  }

  const handleSubmit = () => {
    if (fullName == "" || eMail == "" || message == "" || subject == "") {
      setErrors(true);
    } else {
      fetch(`${Aurl}/default/sendContactForm`, {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          receiverMail: "contact@setugram.com",
          subject: x,
          receiverName: fullName,
          message: message,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result === "True") {
            fetch(`${url}/y-contact-forms`, {
              method: "post",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
              body: JSON.stringify({
                name: fullName,
                email: eMail,
                message: message,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.statusCode === 400) {
                  alert("Please enter a valid email address");
                } else {
                  if (isNaN(data.id)) {
                    alert("Your response has been submitted");
                  } else {
                    alert("Your response has been submitted");
                  }
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <Form>
      <Label>Full Name</Label>
      <Input
        value={fullName}
        onChange={handleFullNameChange}
        placeholder="Enter your name"
      />
      {errors == true && fullName == "" ? (
        <strong style={{ color: "red", fontSize: "15px" }}>
          name is required
        </strong>
      ) : null}
      <Label>E-Mail</Label>
      <Input
        type="email"
        value={eMail}
        onChange={handleEMailChange}
        placeholder="Enter your e-mail"
        pattern=".+@globex\.com"
        size="30"
        required
      />
      {errors == true && eMail == "" ? (
        <strong style={{ color: "red", fontSize: "15px" }}>
          email is required
        </strong>
      ) : null}
      <Label>Subject</Label>
      <Input
        value={subject}
        onChange={handleSubjectChange}
        placeholder="Subject"
      />
      {errors == true && subject == "" ? (
        <strong style={{ color: "red", fontSize: "15px" }}>
          subject is required
        </strong>
      ) : null}
      <Label>Message</Label>
      <TextArea
        value={message}
        onChange={handleMessageChange}
        placeholder="Enter your message"
        rows="5"
      ></TextArea>
      {errors == true && message == "" ? (
        <strong style={{ color: "red", fontSize: "15px" }}>
          message is required
        </strong>
      ) : null}
      <ButtonWrapper>
        <Button onClick={() => handleSubmit()} type="submit">
          SUBMIT
        </Button>
      </ButtonWrapper>
    </Form>
  );
}
const ButtonWrapper = styled.div`
  display: grid;
  height: 8em;
  place-items: center;
  width: 100%;
  @media (max-width: 480px) {
    height: 4em;
  }
`;

const TextArea = styled.textarea`
  margin-top: 0.5em;
  resize: none;
  background-color: inherit;
  border: 1px solid #a5a1a1;
  font-size: 18px;
  line-height: 22px;
  padding: 0.5em;
`;
const Button = styled.button`
  background-color: black;
  color: white;
  outline: none;
  border: none;
  padding: 0.5em 1em;
  font-size: 1.1em;
  border-radius: 0.4em;
  @media (max-width: 480px) {
    font-size: 21px;
  }
`;
const Form = styled.form`
  width: 50%;
  border: 1px solid #a5a1a1;
  border-radius: 5px;
  padding: 3.5em;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 21px;
  line-height: 22px;
  @media (max-width: 480px) {
    width: 100%;
    padding: 0 1em;
    margin-top: 1em;
  }
`;
const Label = styled.label`
  margin-top: 1.5em;
  @media (max-width: 480px) {
    margin-top: 1em;
    font-size: 21px;
  }
`;
const Input = styled.input`
  margin-top: 0.5em;
  border: 1px solid #a5a1a1;
  background-color: inherit;
  outline: none;
  padding: 0.5em;
  font-size: 18px;
  line-height: 22px;
`;
