import React from "react";
import styled from "styled-components";
export default function index() {
  const title = "Categories";
  const link = "/products";
  return <Wrapper></Wrapper>;
}

const Wrapper = styled.div`
  @media (min-width: 1440px) {
    /* width: 1440px; */
    margin: 0 auto;
    margin-top: 4em;
  }
  margin-top: 4em;
`;
