import React from "react";
import styled from "styled-components";
import whoweare from "../../assets/images/whoweare.jpg";

export default function Achievement() {
  return (
    <Wrapper style={{}}>
      <WrapperXX>
        <Title>
          {/* <h1 style={{ textAlign: "center", margin: "0 0 1em 0 ", fontWeight: "500", color: "#000000", opacity: "0.5" }}> */}
          Meet the Founder
          {/* </h1> */}
        </Title>
        <Wrapper2>
          <div
            style={{
              margin: "0",
              display: "",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div style={{ display: "block", textAlign: "justify" }}>
              <Subtitle>
                SetuGram is founded by Nidhi Garg Goyal, an Educator, and
                Blogger by profession with more than 12 years of combined work
                experience in the Fashion Industry and Educational field.
              </Subtitle>
              <Subtitle>
                Nidhi understands that a lot of talented designers, don’t land
                their dream job just because they don’t come from well-known
                Design Institutes, and finding internships/jobs gets difficult
                because they don’t have the relevant work experience. And hence,
                provides freshers with the platform to showcase their talent to
                the big wide world of urbanization. She strongly believes that
                "No institute can teach you creativity, as it comes from within
                and the hard work is what makes one successful."
              </Subtitle>
              <Subtitle>
                She is also a Sustainability Enthusiast helping the lesser-known
                artisans and their craft establish a foundation and make more
                sales through her various social media channels.
              </Subtitle>
            </div>
          </div>
          <WrapperImg>
            <img
              className="swiper-slide-image"
              src={whoweare}
              alt="nidhi garg goyal"
              style={{ height: "30em", width: "auto" }}
            />
          </WrapperImg>
        </Wrapper2>
      </WrapperXX>
      {/* <AchievementsDiv>
				<AchievementCard num='50' info='PROJECTS' />
				<AchievementCard num='50' info='MENTORS MET' />
				<AchievementCard num='100' info='STUDENTS' />
			</AchievementsDiv> */}
    </Wrapper>
  );
}

const AchievementCard = ({ num, info }) => {
  return (
    <CardWrapper>
      <CardNum>{num}+</CardNum>
      <CardInfo>{info}</CardInfo>
    </CardWrapper>
  );
};

const CardInfo = styled.h3`
  font-size: 1.6em;
  margin-bottom: 2.5em;
  color: black;
  @media (max-width: 480px) {
    font-size: 10px;
    font-weight: 400;
  }
`;

const CardNum = styled.p`
  margin-top: 1em;
  font-size: 4em;
  color: black;
  @media (max-width: 480px) {
    font-size: 19px;
  }
`;

const CardWrapper = styled.div`
  font-size: 22px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.51);
  width: 338px;
  height: 323px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  @media (max-width: 480px) {
    width: 85px;
    height: 85px;
    border-radius: 0;
  }
`;

const AchievementsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
  margin: 2em auto 0 auto;
  max-width: 1200px;
  gap: 4em;
  @media (max-width: 480px) {
    gap: 15px;
  }
`;

const Subtitle = styled.p`
  margin: 1.1em 2em 1.1em 0;
  width: 100%;
  align-self: center;
  font-size: 1.15em;
  color: rgba(0, 0, 0, 0.51);
  @media (max-width: 480px) {
    margin-top: 0.6em;
  }
`;

// const Title = styled.h3`
// 	font-size: 3em;
// 	@media (max-width: 480px) {
// 		font-size: 22px;
// 	}
// `;
const Title = styled.h3`
  text-align: center;
  font-size: 3em;
  line-height: 58px;
  opacity: 0.5;
  @media (max-width: 480px) {
    font-size: 21px;
    line-height: 23px;
  }
`;
const Wrapper = styled.div`
  text-align: center;
  /* flex-direction: column; */
  justify-content: center;
  background-color: #fff56c;
  padding: 3em;
  margin-top: 3em;
  @media (max-width: 480px) {
    display: block;
  }
`;

const WrapperXX = styled.div`
  /* display: flex;
	justifyContent : space-between; */
  @media (max-width: 480px) {
    display: block;
  }
`;
const Wrapper2 = styled.div`
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justifycontent: space-between;
  @media (max-width: 480px) {
    display: block;
  }
`;
const WrapperImg = styled.div`
  margin: 0 0 0 8em;
  @media (max-width: 480px) {
    margin: 0;
  }
`;
