import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CartItem = styled.div`
  background-color: black;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  text-align: center;
  color: #fff;
`;
export const IMG = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 1em;
  @media screen and (max-width: 768px) {
    /* width: 80px;
		height: 35px;
		font-size: 1em; */
    margin: 0;
    height: 45px;
    width: 45px;
    border-radius: 45px;
    margin: 0 60px 0 0;
  }
  @media (max-width: 480px) {
    margin: 0;
  }
`;

export const Nav = styled.nav`
  background: #ffffff;
  padding: 0.3em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid rgb(200, 200, 200);
  z-index: 12;
  position: fixed;
  top: 0;
  width: 100%;
`;

export const NavLinkX = styled.p`
  color: #808080;
  align-items: center;
  text-decoration: none;
  padding: 0 1em;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 0.85em;
  margin-top: auto;
  margin-bottom: auto;
`;
export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1em;
  height: 100%;
  cursor: pointer;
  &:active {
    color: #000000;
    text-decoration: underline;
  }
  left: calc(50% - 50px / 2 - 298px);
  top: 0.71%;
  bottom: 98.84%;
  font-style: normal;
  font-weight: bold;
  font-size: 1.15em;
  line-height: 22px;
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  /* background-color: black; */
  width: 8rem;
  justify-content: space-between;
  margin-right: 24px;
  @media screen and (max-width: 768px) {
  }
`;

export const NavBtnLink = styled.button`
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff8ad;
    color: #555;
  }
  width: 118px;
  height: 46px;
  margin: 14.25px 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 10px;
  font-size: 1.3em;
  background-color: #fff56c;
  color: #000;
  text-decoration: none;
  outline: none;
  border: none;
  &:active {
    color: #000000;
  }
  @media screen and (max-width: 768px) {
    width: 80px;
    height: 35px;
    font-size: 1em;
    margin: 1.5% 15% 0 0;
  }
`;
export const NavLinkEm = styled.a`
  color: #adadad;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1em;
  height: 100%;
  cursor: pointer;
  &:active {
    color: #fff56c;
    text-decoration: underline;
  }
  left: calc(50% - 50px / 2 - 298px);
  top: 0.71%;
  bottom: 98.84%;
  font-style: normal;
  font-weight: bold;
  font-size: 1.15em;
  line-height: 22px;
`;
export const DIV = styled.div`
  @media screen and (max-width: 768px) {
  }
  @media (max-width: 480px) {
    margin: 0;
  }
`;
export const DivC = styled.div`
  position: relative;

  @media screen and (max-width: 768px) {
  }
  @media (max-width: 480px) {
    margin: 0;
  }
`;
