import React from "react";
import styled from "styled-components";
export default function Title({ titleText, subTitleText, textSide }) {
  let flexDirection = "row";
  if (textSide === "right") {
    flexDirection = "row-reverse";
  }
  return (
    <HeadingContainer textSide={textSide}>
      <Heading flexDirection={flexDirection}>
        <H3>{titleText}</H3>
        <Line></Line>
      </Heading>
      <P>{subTitleText}</P>
    </HeadingContainer>
  );
}

const Heading = styled.section`
  display: flex;
  align-items: center;
  gap: 1em;
  flex-direction: ${(props) => props.flexDirection};
`;

const Line = styled.div`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  height: 0;
`;

const P = styled.p`
  font-size: 1.1em;
  margin-top: 0.7em;
  color: rgba(0, 0, 0, 0.51);
  @media (max-width: 480px) {
    font-size: 1.2em;
  }
`;

const H3 = styled.h3`
  font-size: 1.6em;
  font-weight: normal;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  text-align: ${(props) => props.textSide || "left"};
`;
