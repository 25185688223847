import React, { useState, useContext, createContext } from "react";

const ItemContext = createContext();

export function UseSetAddress() {
  return useContext(ItemContext);
}
// const [AddressC, setAddressC] = useState('');
export function Context({ children }) {
  const [AddressC, setAddressC] = useState("address");

  const value = { AddressC, setAddressC };
  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}
