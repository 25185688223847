import React from "react";
import styled from "styled-components";
export default function Features() {
  return (
    <FeatureContainer>
      {/* <Title>Our Exciting Features</Title>
			<Subtitles>
				Ac ac eleifend sed feugiat. Dolor, pretium in sed consectetur
				placerat vulputate. Ac ac eleifend sed feugiat. Dolor, pretium
				in sed
			</Subtitles> */}
    </FeatureContainer>
  );
}

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 480px) {
    /* margin-top: 2em; */
  }
`;
const Title = styled.h2`
  margin-top: 2em;
  width: 284px;
  font-size: 1.6em;
  @media (max-width: 480px) {
    font-size: 22px;
    margin-top: 1em;
  }
`;
const Subtitles = styled.p`
  width: 763px;
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3em;
  margin-top: 1em;
  line-height: 1.3em;
  color: rgba(0, 0, 0, 0.51);
  width: 65%;
  @media (max-width: 480px) {
    font-size: 1.2em;
    margin-top: 0.5em;
  }
`;
