// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import {
  InterWrapper,
  Title,
  Author,
  Details,
  DetailSection,
  DetailInfoD,
  DetailInfo,
  DetailTitle,
  ButtonWrapper,
} from "./InternshipInfoElements";
import { SkillsSection, SkillButton } from "./InternshipCardElements";
import AboutInfo from "./InternshipDetailComponents/AboutInfo";
import AboutCompany from "./InternshipDetailComponents/AboutCompany";
import Button from "../../Components/Button";
import ReactMarkdown from "react-markdown";
import ApplyNow from "./ApplyNow";
import styled from "styled-components";
import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

export default function InternshipInfo({
  Internship,
  ChecklogUser,
  setChecklogUser,
}) {
  const USER_ID = localStorage.getItem("setugramuser");
  const [applynowisopen, setApplyNowIsOpen] = useState(false);
  const [setresume, setSetresume] = useState();
  const [setlinkedind, setSetLinkedin] = useState();
  const [settags, setSetTags] = useState([]);
  const [previousAppliedInternship, setPreviousAppliedInternship] = useState(
    []
  );

  const handleClose = () => {
    setApplyNowIsOpen(false);
  };

  const checklog = () => {
    if (ChecklogUser === false) {
      setChecklogUser(true);
    } else {
      setChecklogUser(false);
    }
  };

  useEffect(() => {
    fetch(`${url}/users/${USER_ID}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setSetTags(
          data.skills.map((item) => {
            return item.id;
          })
        );
        setPreviousAppliedInternship(
          data.appliedInternships.map((item) => {
            return item.id;
          })
        );
        setSetresume(data.resume);
        setSetLinkedin(data.linkedInProfile);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [ChecklogUser]);

  const AppliedIntern = () => {
    axios
      .put(`${url}/users/${USER_ID}`, {
        appliedInternships: [...previousAppliedInternship, `${Internship.id}`],
      })
      .then((response) => {
        alert("Succesfully Applied");
        checklog();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //
  var checkbutton = false;
  var checkbutton2 = false;

  return (
    <>
      <InterWrapper>
        <Title>{Internship.title}</Title>
        <Author>{Internship.employer.name}</Author>
        <Details>
          <DetailSection>
            <DetailTitle>Start Date</DetailTitle>
            <DetailInfo>{Internship.startDate}</DetailInfo>
          </DetailSection>
          <DetailSection>
            <DetailTitle>Duration</DetailTitle>
            {Internship.duration ? (
              <DetailInfo>{Internship.duration.months} months</DetailInfo>
            ) : null}
          </DetailSection>
          <DetailSection>
            <DetailTitle>Stipend</DetailTitle>
            <DetailInfo>Rs.{Internship.stipendPerMonth} p.m</DetailInfo>
          </DetailSection>
          <DetailSection>
            <DetailTitle>Location</DetailTitle>
            <DetailInfo>{Internship.location.name}</DetailInfo>
          </DetailSection>
        </Details>

        <AboutCompany employer={"Internship Details"} />
        <DetailInfoD>
          <ReactMarkdown>{Internship.description}</ReactMarkdown>
        </DetailInfoD>

        <AboutCompany employer={"About the Company"} />
        <DetailInfoD>
          <ReactMarkdown>{Internship.aboutCompany}</ReactMarkdown>
        </DetailInfoD>

        <AboutInfo title="Who can apply" data={Internship.eligibility} />
        <SkillsSection>
          <DetailTitle>Skills required : </DetailTitle>
          {Internship.tags.map((skill, index) => (
            <SkillButton key={index}>{skill.name}</SkillButton>
          ))}
        </SkillsSection>
        <AboutInfo title="Perks" data={Internship.perks} />
        {Internship.applicants &&
          Internship.applicants.map(
            (item) =>
              item.id === Number(USER_ID) && (
                <>
                  {(checkbutton = true)}
                  <ButtonWrapper>
                    <Buttongray>Already Applied</Buttongray>
                  </ButtonWrapper>
                </>
              )
          )}
        {checkbutton === false &&
          setresume !== null &&
          setlinkedind !== "" &&
          settags.length > 0 &&
          Internship.isActive === true && (
            <>
              {(checkbutton2 = true)}
              <ButtonWrapper onClick={() => AppliedIntern()}>
                <Button Name="Apply Now" />
              </ButtonWrapper>
            </>
          )}
        {checkbutton === false &&
          checkbutton2 === false &&
          Internship.isActive === true && (
            <ButtonWrapper onClick={() => setApplyNowIsOpen(true)}>
              <Button Name="Apply Now" />
            </ButtonWrapper>
          )}
      </InterWrapper>

      <ApplyNow
        addAddressIsOpen3={applynowisopen}
        handleClose3={handleClose}
        internshipid={Internship.id}
      ></ApplyNow>
    </>
  );
}

const Buttongray = styled.button`
  display: flex;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.2em;
  background-color: #bbb;
  color: #fff;
`;

const Wrapper = styled.div`
  margin-top: 4em;
  @media (max-width: 480px) {
    margin-top: 2em;
    padding: 0 0.5em;
  }
`;

InternshipInfo.defaultProps = {
  Internship: {
    employer: {},
    duration: {},
    location: {},
    eligibility: "",
    tags: [],
    perks: "",
  },
};
