import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import Auth from '../../ApiServices/Auth';
import Modal from "react-modal";

const url = process.env.REACT_APP_BASE_URL;

const Aurl = process.env.REACT_APP_LAMDA_URL;

export default function Customise(props) {
  const { ModalIsOpen, setModalIsOpen, productName, id } = props;
  const USER_ID = localStorage.getItem("setugramuser");

  const [email, setEmail] = useState("");
  const [BaseColor, setBaseColor] = useState("");
  const [ThreadWeftColor, setThreadWeftColor] = useState("");
  const [DesignStyle, setDesignStyle] = useState("");
  const [Description, setDescription] = useState("");
  const [ChecklogUser, setChecklogUser] = useState(true);

  function handleBaseColor(event) {
    setBaseColor(event.target.value);
  }
  function handleDesignStyle(event) {
    setDesignStyle(event.target.value);
  }
  function handleThreadColor(event) {
    setThreadWeftColor(event.target.value);
  }
  function handleDescription(event) {
    setDescription(event.target.value);
  }
  function handleEmail(event) {
    setEmail(event.target.value);
  }

  useEffect(() => {}, []);

  const checklog = () => {
    if (ChecklogUser === false) {
      setChecklogUser(true);
    } else {
      setChecklogUser(false);
    }
  };

  const handleSubmit = () => {
    fetch(`${Aurl}/default/sendContactForm`, {
      method: "post",
      headers: {
        "Content-Type": "html/application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        receiverMail: "contact@setugram.com",
        subject: "Custom product requests",
        receiverName: USER_ID,
        message: `Hi there,
				
${email} has requested to customise product "${productName}" with these details:

Product id - ${id}
Base color - ${BaseColor}
Thread weft color - ${ThreadWeftColor}
Design style - ${DesignStyle}
Description - ${Description}

Reply to this email for further conversation,
Sincerely,
User Support
SetuGram `,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
      });

    fetch(`${url}/y-custom-product-requests`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        name: productName,
        users_permissions_user: USER_ID,
        // "email": email,
        product: id,
        variation: {
          baseColor: BaseColor,
          weftThreadColor: ThreadWeftColor,
          designStyle: DesignStyle,
        },
        description: Description,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 400) {
          alert("Please enter a valid email address");
        } else {
          if (isNaN(data.id)) {
            alert("Your response has been submitted");

            setModalIsOpen(false);
          } else {
            alert("Your response has been submitted");

            setModalIsOpen(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <ModalS
      isOpen={ModalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <Label>Customise</Label>
          <FormField>
            <InputLabel>Base Color</InputLabel>
            <br />
            <InputField
              type="text"
              placeholder="Enter Base Color"
              value={BaseColor}
              onChange={handleBaseColor}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Thread Weft Color</InputLabel>
            <br />
            <InputField
              type="text"
              placeholder="Enter Thread Weft Color"
              value={ThreadWeftColor}
              onChange={handleThreadColor}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Design Style</InputLabel>
            <br />
            <InputField
              type="text"
              placeholder="Enter Design Style"
              value={DesignStyle}
              onChange={handleDesignStyle}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Description</InputLabel>
            <br />
            <InputField
              type="text"
              placeholder="Description"
              value={Description}
              onChange={handleDescription}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Email</InputLabel>
            <br />
            <InputField
              type="text"
              placeholder="Email"
              value={email}
              onChange={handleEmail}
            ></InputField>
          </FormField>
          <ButtonWrapper>
            <Button
              onClick={() => handleSubmit()}
              onSubmit={handleSubmit}
              type="button"
            >
              Order
            </Button>
          </ButtonWrapper>
        </Form>
      </div>
    </ModalS>
  );
}

const Sbutton = styled.button`
  outline: none;
  color: #004;
  background-color: #fff;
  font-size: 16px;
  border: none;
  &:hover {
    color: #00a;
  }
`;

const ModalS = styled(Modal)`
  width: 526px;
  height: auto;
  /* min-height: 526px;
	max-height: 800px; */
  padding: 2em 4em 0 4em;
  border: black 1px solid;
  /* margin: 4em; */
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  margin-top: 2em;
  @media (max-width: 480px) {
    font-size: 12px;
    width: 280px;
    max-height: 100%;
    min-height: 350px;
    margin: 0;
    padding: 2em;
  }
`;

const Form = styled.form`
  text-align: center;
  z-index: 1000;
  @media (max-width: 480px) {
  }
`;

const NewAccountWrapper = styled.p`
  margin-top: 1em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 1em;
`;

const ButtonWrapper = styled.div`
  padding: 0 0 1em;
`;

const ForgetPasswordWrapper = styled.div`
  text-align: right;
  margin-top: 0.3em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 1em;
  margin-top: 0.5em;
  border-radius: 10px;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 0.2em;
  }
`;

const InputLabel = styled.label`
  font-size: 1em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 2%;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.5em;
  line-height: 1.2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
