import React, { useState } from "react";
import styled from "styled-components";
import Filter from "./Filter";
import Categories from "./Components/Categories";
import ProductsList from "./Components/ProductsList";
import { useParams } from "react-router";
//
export default function Products({ searchtags }) {
  const [category, setCategory] = useState();
  const [maxprice, setMaxPrice] = useState();
  const params = useParams();
  const handleCategory = (newCategory) => {
    if (newCategory === category) {
      setCategory();
    } else {
      setCategory(newCategory);
    }
  };
  //
  return (
    <>
      <div style={{ height: "70vh" }}>
        hiiiiiiii
        <h1>Coming soon </h1>
      </div>

      {/* <Wrapper1>
				<Heading>Products</Heading>
				<p>Customize the products and get one of kind and most sustainable shopping experience </p>
			</Wrapper1>
			<Wrapper>
				<Filter setMaxPrice={setMaxPrice} />
				<ProductsWrapper>
					<Categories handleCategory={handleCategory} />
					<ProductsList category={category} searchtags={searchtags} maxprice={maxprice} homecategory={params.categoryname} />
				</ProductsWrapper>
			</Wrapper> */}
    </>
  );
}

const ProductsWrapper = styled.section`
  margin: 0 0 0 1.4em;
  width: 100%;
  @media (max-width: 480px) {
    margin: 0 !important;
  }
`;

const Heading = styled.h3`
  margin: 0.5em 0;
  font-size: 2em;
`;

const Wrapper = styled.div`
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
    margin-top: 1em;
  }
  padding: 0 3em;
  margin-top: 1em;
  display: flex;
  width: 100%;
  position: relative;
  gap: 1em;
  @media (max-width: 480px) {
    padding: 0;
    margin-top: 3em;
    width: 100%;
  }
`;
const Wrapper1 = styled.div`
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
    margin-top: 9em;
  }
  padding: 0 3em;
  margin-top: 13em;
  width: 100%;
  position: relative;
  gap: 0em;
  @media (max-width: 480px) {
    padding: 0;
    margin-top: 9em;
    width: 100%;
  }
`;
