import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import Button from "../../Components/Button";
import styled from "styled-components";
import { UseSetAddress } from "../../Components/context";

import {
  InterWrapper,
  Title,
  ButtonWrapper,
} from "../Internships/InternshipInfoElements";
const url = process.env.REACT_APP_BASE_URL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "visible",
    width: "80%",
    marginTop: "2rem",
    transform: "translate(-50%, -50%)",
    paddingTop: 50,
    paddingBottom: 50,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const AddressPopup = ({ addAddressIsOpenAD, handleCloseAD }) => {
  const { AddressC, setAddressC } = UseSetAddress();

  const USER_ID = localStorage.getItem("setugramuser");
  const [getuserdetail, setgetUserDetail] = useState([]);
  const [applynowisopen, setApplyNowIsOpen] = useState(false);

  const handleClose = () => {
    setApplyNowIsOpen(false);
  };

  const [setaddress, setSetAddress] = useState("");
  const [seta, setSetA] = useState("");
  const [setb, setSetB] = useState("");
  const [setc, setSetC] = useState("");
  const [setd, setSetD] = useState("");
  const [updatebutton, setUpdateButton] = useState(false);

  useEffect(() => {
    fetch(`${url}/users/${USER_ID}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getdetails) => {
        setgetUserDetail(getdetails);
        setSetAddress(getdetails.address);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [applynowisopen]);

  const updateForm = () => {
    if (seta !== "" && setb !== "" && setc !== "" && setd !== "") {
      axios
        .put(`${url}/users/${USER_ID}`, {
          address: `${seta}__${setb}__${setc}__${setd}`,
        })
        .then((response) => {
          alert("Your address is been updated !");
          handleCloseAD();
          setAddressC(`${seta}__${setb}__${setc}__${setd}`);
        })
        .catch((error) => {
          alert("Please try again after some time !");
        });
    } else {
      alert("Please All field is required !");
    }
  };

  let AddressT = "";

  if (setaddress != null && setaddress.length !== 0) {
    AddressT = getuserdetail.address;
  }

  let a = AddressT.split("__")[0];
  let b = AddressT.split("__")[1];
  let c = AddressT.split("__")[2];
  let d = AddressT.split("__")[3];

  // setSetA = a;

  return (
    <Modal
      isOpen={addAddressIsOpenAD}
      onRequestClose={handleCloseAD}
      style={customStyles}
      ariaHideApp={false}
    >
      <InterWrapper>
        <Title>Address </Title>
        {getuserdetail && (
          <div style={{ marginTop: "2rem" }}>
            <label htmlFor="fInput">Address line 1</label>
            <br />
            <input
              defaultValue={a}
              setSetA={a}
              onChange={(e) => {
                setSetA(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">Address line 2</label>
            <br />
            <input
              defaultValue={b}
              setSetB={b}
              onChange={(e) => {
                setSetB(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">Pin code</label>
            <br />
            <input
              defaultValue={c}
              setSetC={c}
              onChange={(e) => {
                setSetC(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">State</label>
            <br />
            <input
              defaultValue={d}
              setSetD={d}
              onChange={(e) => {
                setSetD(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <br />
            <div
              className="appId-subcon4 row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* {
                                AddressT === "" &&
                                <div>
                                    <ButtonWrapper onClick={() => updateForm()}>
                                        <Button Name='Add Address' />
                                    </ButtonWrapper>
                                </div>
                            } */}
            </div>
          </div>
        )}
        {updatebutton === true && (
          <ButtonWrapper onClick={() => updateForm()}>
            <Button Name="Update Address" />
          </ButtonWrapper>
        )}
      </InterWrapper>
    </Modal>
  );
};

export default AddressPopup;
