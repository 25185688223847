import styled from "styled-components";

export const Container = styled.div`
  margin-top: 4em;
`;
export const YellowWrapper = styled.section`
  background-color: #fff56c;
  height: auto;
  padding-top: 4em;
  position: "relative";
  padding-bottom: 0.5em;
  padding-left: 4em;
  @media (max-width: 480px) {
    background-color: #fff;
    height: 100%;
    padding: 0.5em;
    padding-bottom: 0;
  }
`;

export const InfoWrapper = styled.div`
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
  }
`;

export const Title = styled.h1`
  font-size: 2em;
  @media (max-width: 480px) {
    font-size: 21px;
  }
`;

export const Info = styled.p`
  max-height: 162px;
  width: 60%;
  margin-top: 0.6em;
  overflow: hidden;
  @media (max-width: 480px) {
    max-height: 163px;
    width: 100%;
  }
`;

export const MiddleSection = styled.section`
  display: flex;
  padding: 0 4em;
  justify-content: space-around;
  margin-bottom: 3em;
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  @media (max-width: 480px) {
    display: flex;
    padding: 0.5em;
    flex-direction: column-reverse;
  }
`;
export const BenefitsWrapper = styled.div`
  display: flex;
  gap: 1em;
  padding-top: 5em;
  width: 744px;
  height: 221;
  @media (max-width: 480px) {
    width: 100%;
    gap: 0.3em;
    padding: 0;
    align-items: center;
    justify-content: space-evenly;
  }
`;
