import Styled from "styled-components";
import React, { useState, useEffect } from "react";
import Filters from "./Components/Filters";
import CoursesSection from "./Components/CoursesSection";
import FeaturedSection from "./Components/FeaturedSection";
import CoursesSearchBar from "../../Components/shared/CoursesSearchBar";
import { useParams } from "react-router";

const Courses = ({ id }) => {
  const [searchtags, setSearchTags] = useState(
    localStorage.getItem("choosechoose")
      ? JSON.parse(localStorage.getItem("choosechoose"))
      : []
  );
  const [searchArray, setSearchArrray] = useState(
    localStorage.getItem("choosearrray")
      ? JSON.parse(localStorage.getItem("choosearrray"))
      : []
  );
  const [duration, setDuration] = useState();
  const [category, setCategory] = useState();

  const params = useParams();
  const homecategory = params.categoryname;
  const [maxprice, setMaxPrice] = useState();

  return (
    <div id={id}>
      <Container>
        <SubWrapper>
          <CoursesSearchBar
            setSearchTags={setSearchTags}
            deafaultsearch={searchArray}
          />
        </SubWrapper>
        <Title>Courses</Title>
        <SubTitle>Enrol with us to kickstart your career</SubTitle>
        <Wrapper>
          <Filters
            setduration={setDuration}
            setcategory={setCategory}
            setMaxPrice={setMaxPrice}
            homecategory={homecategory}
          />
          <CoursesSection
            searchtags={searchtags}
            duration={duration}
            category={category}
            maxprice={maxprice}
            homecategory={homecategory}
          />
        </Wrapper>
        <FeaturedSection />
      </Container>
    </div>
  );
};

const Title = Styled.h2`
    font-size: 2em;
    @media (max-width: 480px) {
        font-size: 21px;
    }
`;
const SubTitle = Styled.p`
    /* font-size: 1.3em; */
    margin-top: .3em;
    @media (max-width: 480px) {
        font-size: 17px;
    }
`;
const Container = Styled.section`
    padding: 2em 0.6em;
    padding-bottom: 1em;
    height: 100%;
    margin-top: 4em;
    @media (min-width: 1440px) {
		width: 1440px;
		margin: 0 auto;
        margin-top: 4em;
	}
    @media (max-width: 480px) {
        padding: 1em 0.6em;
    }
`;
const Wrapper = Styled.section`
    margin-top: 1.5em;
    gap: 2em;
    display: flex;
`;
const SubWrapper = Styled.section`
	width: calc(100% - 2em);
	/* position: absolute; */
	top: 4em;
	padding: 0.45em 7.5em;
	@media (max-width: 480px) {
		display: none;
	}
`;
export default Courses;
