import styled from "styled-components";

export const VariantDiv = styled.div`
  margin-top: 1em;
`;
//
export const ImgDiv = styled.div`
  height: 250px;
  min-width: 336px;
  /* margin: 4.5em 0 0 3em; */
  margin: 0 0 0 3em;

  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
    min-width: 328px;
  }
`;

export const VariationLabel = styled.p`
  display: inline;
  font-size: 18px;
  margin-right: 0.1em;
`;

export const Label = styled.label`
  margin-right: 1em;
  margin-left: 0.2em;
`;

export const Wrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 1176px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 10em;
  padding-top: 4em;
  overflow: hidden;
  padding-bottom: 1em;
  @media (max-width: 480px) {
    border: 0;
    margin-top: 5em;
    height: 100%;
    width: 100%;
    padding: 0 1em;
  }
`;

export const UpperSection = styled.section`
  display: flex;
  justify-content: left;
  gap: 2em;
  @media (max-width: 480px) {
    display: block;
    height: 100%;
  }
`;
export const Info = styled.p`
  color: rgba(0, 0, 0, 0.51);
  font-size: 1.2em;
  width: 100%;
  height: 140px;
  overflow: hidden !important;
  @media (max-width: 480px) {
    padding: 0;
    height: 130px;
    font-size: 16px;
  }
`;
export const Color = styled.p`
  font-size: 1.4em;
  font-weight: 400;
`;
export const Pattern = styled.p`
  font-size: 1.4em;
  font-weight: 400;
`;
export const Quality = styled.div`
  font-size: 1.4em;
  font-weight: 400;
  max-width: 100px;
  display: flex;
  justify-content: space-between;
`;
export const QualityDiv = styled.div``;
export const FilterDiv = styled.div``;
export const LowerSection = styled.section`
  display: flex;
  padding-right: 3em;
  margin: 1em 0 0;
  justify-content: flex-end;
  @media (max-width: 480px) {
    display: block;
    width: 100%;
    padding-right: 0;
    justify-content: space-between;
  }
`;

export const Name = styled.h4`
  font-size: 1.7em;
  padding: 0 0 1.2em;
  @media (max-width: 480px) {
    font-size: 21px;
  }
`;
export const ProductInfoDiv = styled.div`
  height: 185px;
  margin: 0 1em 0 0;
  @media (max-width: 480px) {
    padding-top: 1em;
    height: 100%;
  }
`;
export const IMG = styled.img`
  object-fit: fill;
  width: 100%;
  height: 100%;
`;
export const AddToCartWrapper = styled.div`
  width: 386px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
export const InfoWrapper = styled.div`
  width: 661px;
  height: 5em;
`;
export const Price = styled.p`
  font-size: 1.6em;
  padding: 0 0 0 2em;
  font-weight: 500;
  @media (max-width: 480px) {
    padding: 0;
  }
`;
export const InfoWrapperI = styled.div`
  display: flex;
  font-size: 1.1em;
  height: 1rem;
  font-weight: 500;
  margin-top: 1rem;
  gap: 0.5rem;
  width: 100%;
  color: rgba(0, 0, 0, 0.51);
  margin-right: 0.1em;
`;
