import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 1.7em;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 1.1em;
  }
`;
export const List = styled.ul`
  max-width: 700px;
  padding-left: 1em;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.51);
  padding-top: 0.6em;
  @media (max-width: 480px) {
    padding-top: 0.3em;
    font-size: 1em;
  }
`;
export const ListItem = styled.li`
  margin-top: 0.3em;
  @media (max-width: 480px) {
    margin-top: 0.2em;
    font-size: 1.1em;
  }
`;
export const Title = styled.h5`
  font-size: 1.5em;
  @media (max-width: 480px) {
    font-size: 1.1em;
    font-weight: 500;
  }
`;
