import React, { useState } from "react";
import styled from "styled-components";
import Auth from "../../ApiServices/Auth";
import Modal from "react-modal";

const url = process.env.REACT_APP_BASE_URL;
Modal.setAppElement("#root");
export default function RegisterModal(props) {
  const {
    RegisterModalIsOpen,
    setRegisterModalIsOpen,
    setModalIsOpen,
    setIsLogged,
  } = props;
  const [Name, setName] = useState(null);
  const [MobileNo, setMobileNo] = useState(null);
  const [Email, setEmail] = useState(null);
  const [Password, setPassword] = useState(null);
  const [ConfirmPassword, setConfirmPassword] = useState(null);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNumberChange = (e) => {
    setMobileNo(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const user = {
    email: Email,
    password: Password,
    contact: MobileNo,
    username: Name,
  };
  const handleSignUp = () => {
    Auth.Signup(user, setRegisterModalIsOpen, setIsLogged);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleSignUp();
    var r = window.confirm(
      "Would you like to get notified for paid Internship?"
    );
    if (r === true) {
      handleSubmit1();
    }
  };

  const handleSubmit1 = () => {
    fetch(`${url}/subscriptions`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        email: Email,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <ModalS
      isOpen={RegisterModalIsOpen}
      onRequestClose={() => setRegisterModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          // height: "60vh",
        },
      }}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <Label>Register</Label>
          <FormField>
            <InputLabel>Name/Company</InputLabel>
            <br />
            <InputField
              type="text"
              placeholder="Enter your Name/Company Name"
              value={Name}
              required="true"
              onChange={handleNameChange}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>E-mail</InputLabel>
            <br />
            <InputField
              type="email"
              placeholder="Enter your email address"
              value={Email}
              required="true"
              onChange={handleEmailChange}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Moblie no.</InputLabel>
            <br />
            <InputField
              type="tel"
              placeholder="Enter your Mobile number"
              value={MobileNo}
              required="true"
              onChange={handleNumberChange}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Password</InputLabel>
            <br />
            <InputField
              type="password"
              placeholder="Enter your password"
              value={Password}
              onChange={handlePasswordChange}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Confirm Password</InputLabel>
            <br />
            <InputField
              type="password"
              placeholder="Enter same password again"
              value={ConfirmPassword}
              onChange={handleConfirmPasswordChange}
            ></InputField>
          </FormField>
          {/* <ForgetPasswordWrapper>
						Forget password?
					</ForgetPasswordWrapper> */}
          <ButtonWrapper>
            <Button type="submit">Register</Button>
          </ButtonWrapper>
          <NewAccountWrapper>
            Already have a account?
            <Sbutton
              onClick={() => {
                setRegisterModalIsOpen(false);
                setModalIsOpen(true);
              }}
            >
              &#160;&#160;Login
            </Sbutton>
          </NewAccountWrapper>
        </Form>
      </div>
    </ModalS>
  );
}

const Sbutton = styled.button`
  outline: none;
  color: #004;
  background-color: #fff;
  font-size: 16px;
  border: none;
  &:hover {
    color: #00a;
  }
`;

const ModalS = styled(Modal)`
  width: 526px;
  height: auto;
  padding: 0 3.5em 0 3.5em;
  border: black 1px solid;
  margin: 4em;
  z-index: 1000;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  margin-top: 0;
  @media (max-width: 480px) {
    font-size: 12px;
    width: 280px;
    margin: 0;
    padding: 2em;
  }
`;

const Form = styled.form`
  text-align: center;
  z-index: 1000;
  @media (max-width: 480px) {
  }
`;

const NewAccountWrapper = styled.p`
  margin-top: 0.5em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 0.7em;
`;

const ButtonWrapper = styled.div``;

const InputField = styled.input`
  width: 100%;
  padding: 0.6em;
  margin-top: 0.5em;
  border-radius: 10px;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 0.2em;
  }
`;

const InputLabel = styled.label`
  font-size: 1em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 0.2em;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.9em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
