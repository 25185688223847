import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Auth from "../../ApiServices/Auth";
import Modal from "react-modal";
Modal.setAppElement("#root");
export default function LoginModal(props) {
  const {
    ModalIsOpen,
    setModalIsOpen,
    setIsLogged,
    setRegisterModalIsOpen,
    setForgotModalIsOpen,
  } = props;
  const [Email, setEmail] = useState("");
  useEffect(() => {}, []);
  const [Password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleForgotPassword = () => {
    setModalIsOpen(false);
    setForgotModalIsOpen(true);
  };
  const user = {
    identifier: Email,
    password: Password,
  };
  const handleLogin = async () => {
    await Auth.Login(user, setModalIsOpen, setIsLogged);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <ModalS
      isOpen={ModalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <Label>Login</Label>
          <FormField>
            <InputLabel>E-mail</InputLabel>
            <br />
            <InputField
              type="email"
              placeholder="Enter your email address"
              value={Email}
              onChange={handleEmailChange}
            ></InputField>
          </FormField>
          <FormField>
            <InputLabel>Password</InputLabel>
            <br />
            <InputField
              type="password"
              placeholder="Enter your password"
              value={Password}
              onChange={handlePasswordChange}
            ></InputField>
          </FormField>
          <ForgotPasswordWrapper onClick={handleForgotPassword}>
            Forgot password?
          </ForgotPasswordWrapper>
          <ButtonWrapper>
            <Button type="submit">Login</Button>
          </ButtonWrapper>
          <NewAccountWrapper>
            Don't have a account?
            <Sbutton
              onClick={() => {
                setModalIsOpen(false);
                setRegisterModalIsOpen(true);
              }}
            >
              &#160;&#160;Sign Up
            </Sbutton>
          </NewAccountWrapper>
        </Form>
      </div>
    </ModalS>
  );
}

const Sbutton = styled.button`
  outline: none;
  color: #004;
  background-color: #fff;
  font-size: 16px;
  border: none;
  &:hover {
    color: #00a;
  }
`;

const ModalS = styled(Modal)`
  width: 526px;
  min-height: 526px;
  max-height: 526px;
  padding: 2em 4em 0 4em;
  border: black 1px solid;
  margin: 4em;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  margin-top: 2em;
  @media (max-width: 480px) {
    font-size: 12px;
    width: 280px;
    max-height: 350px;
    min-height: 350px;
    margin: 0;
    padding: 2em;
  }
`;

const Form = styled.form`
  text-align: center;
  z-index: 1000;
  background-color: "#fff";
  @media (max-width: 480px) {
  }
`;

const NewAccountWrapper = styled.p`
  margin-top: 1em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 2em;
`;

const ButtonWrapper = styled.div``;

const ForgotPasswordWrapper = styled.div`
  text-align: right;
  margin-top: 0.3em;
  cursor: pointer;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 1em;
  margin-top: 0.8em;
  border-radius: 10px;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 0.2em;
  }
`;

const InputLabel = styled.label`
  font-size: 1.3em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 1.5em;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.9em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
