import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import styled from "styled-components";
const url = process.env.REACT_APP_BASE_URL;

const CardProductButton = ({ productid, setTotalItem }) => {
  const [getProductQuantity, setGetProductQuantity] = useState([]);
  const [qty, setQty] = useState(false);

  const changeic = () => {
    if (qty === true) {
      setQty(false);
    } else {
      setQty(true);
    }
  };

  useEffect(() => {
    axios
      .get(`${url}/z-product-orders/${productid}`)
      .then((response) => {
        setGetProductQuantity(response.data);
      })
      .then((response) => response);
  }, [qty]);

  const IncreaseProducttoCart = (quant_qty) => {
    axios
      .put(`${url}/z-product-orders/${productid}`, {
        quantity: quant_qty,
      })
      .then((response) => {
        changeic();
        setTotalItem();
      })
      .then((response) => response);
  };

  const decreaseProducttoCart = (quant_qty) => {
    axios
      .put(`${url}/z-product-orders/${productid}`, {
        quantity: quant_qty,
      })
      .then((response) => {
        changeic();
        setTotalItem();
      })
      .then((response) => response);
  };

  return (
    <>
      {getProductQuantity.quantity > 1 && (
        <>
          <ButtonDiv>
            <ButtonCLick
              onClick={() => {
                decreaseProducttoCart(Number(getProductQuantity.quantity) - 1);
              }}
            >
              <FontAwesomeIcon icon={faMinus} size="sm" />
            </ButtonCLick>
            <span className="counter-number">
              {getProductQuantity.quantity}
            </span>
            <ButtonCLick
              onClick={() => {
                IncreaseProducttoCart(Number(getProductQuantity.quantity) + 1);
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </ButtonCLick>
          </ButtonDiv>
        </>
      )}
      {getProductQuantity.quantity === 1 && (
        <>
          <ButtonDiv>
            <ButtonCLick>
              <FontAwesomeIcon color="gray" icon={faMinus} size="sm" />
            </ButtonCLick>
            <span className="counter-number">
              {getProductQuantity.quantity}
            </span>
            <ButtonCLick
              onClick={() => {
                IncreaseProducttoCart(Number(getProductQuantity.quantity) + 1);
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </ButtonCLick>
          </ButtonDiv>
        </>
      )}
    </>
  );
};

const ButtonDiv = styled.div`
  display: flex;
  background-color: black;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  margin-top: 1rem;
  border-radius: 200px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 36px;
  border-radius: 6px;
`;

const ButtonCLick = styled.div`
  border: none;
  background-color: transparent;
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  cursor: pointer;
`;

export default CardProductButton;
