import React from "react";
import styled from "styled-components";
export default function Brandindex({ imageLink }) {
  return (
    <CardContainer>
      <CardImage src={imageLink} alt="Skill Category Image"></CardImage>
    </CardContainer>
  );
}

const CardImage = styled.img`
  width: 100%;
  height: 100% !important;
  border-radius: 10px;
  object-fit: cover;
  @media (max-width: 480px) {
    min-height: 50px;
    min-width: 50px;
  }
`;

const CardContainer = styled.div`
  cursor: pointer;
  width: 195px;
  height: 198px;
  background: #ffffff;
  // border: 2px solid rgba(0, 0, 0, 0.2);
  transition: top ease 0.5s;
  position: relative;
  top: 0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  &:hover {
    top: -10px;
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
  }
  @media (max-width: 480px) {
    min-width: 122px;
    max-height: 120px;
    flex: auto;
    border-radius: 0;
    max-width: 122px;
    border-radius: 10px;
  }
`;
