import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import styled from "styled-components";
import { useItem } from "../../../Components/Context/useContext";
const url = process.env.REACT_APP_BASE_URL;

const ProductButton = ({ productid, productvariant, getbasecolour }) => {
  const { changeValue } = useItem();
  const [getProductQuantity, setGetProductQuantity] = useState([]);
  const [qty, setQty] = useState(false);
  const USER_ID = localStorage.getItem("setugramuser");
  const totalItem = localStorage.getItem("TcartItem");

  var checkingproduct = true;

  const changeic = () => {
    if (qty === true) {
      setQty(false);
    } else {
      setQty(true);
    }
  };

  useEffect(() => {
    if (USER_ID) {
      axios
        .get(`${url}/z-product-orders?buyers.id=${USER_ID}&_status=InCart`)
        .then((response) => {
          setGetProductQuantity(response.data);
        })
        .then((response) => response);
    }
  }, [qty]);

  const addProducttoCart = () => {
    axios
      .post(`${url}/z-product-orders`, {
        status: "InCart",
        product: productid,
        variation: productvariant,
        quantity: "1",
        buyers: [`${USER_ID}`],
      })
      .then((response) => {
        localStorage.setItem("TcartItem", Number(totalItem) + 1);
        changeValue();
        changeic();
      })
      .then((response) => response);
  };

  const deleteProductfromCart = (orderid) => {
    axios
      .delete(`${url}/z-product-orders/${orderid}`)
      .then((response) => {
        localStorage.setItem("TcartItem", Number(totalItem) - 1);
        changeValue();
        changeic();
      })
      .then((response) => response);
  };

  const IncreaseProducttoCart = (quant_qty, orderid) => {
    axios
      .put(`${url}/z-product-orders/${orderid}`, {
        quantity: quant_qty,
      })
      .then((response) => {
        changeic();
      })
      .then((response) => response);
  };

  const decreaseProducttoCart = (quant_qty, orderid) => {
    axios
      .put(`${url}/z-product-orders/${orderid}`, {
        quantity: quant_qty,
      })
      .then((response) => {
        changeic();
      })
      .then((response) => response);
  };

  return (
    <>
      {getProductQuantity &&
        getProductQuantity.map(
          (item, key) =>
            item.product.id === productid &&
            item.variation.baseColor === getbasecolour &&
            item.quantity > 1 && (
              <>
                {(checkingproduct = false)}
                <ButtonDiv key={key}>
                  <ButtonCLick
                    onClick={() => {
                      decreaseProducttoCart(Number(item.quantity) - 1, item.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} size="sm" />
                  </ButtonCLick>
                  <span className="counter-number">{item.quantity}</span>
                  <ButtonCLick
                    onClick={() => {
                      IncreaseProducttoCart(Number(item.quantity) + 1, item.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </ButtonCLick>
                </ButtonDiv>
              </>
            )
        )}

      {getProductQuantity &&
        getProductQuantity.map(
          (item, key) =>
            item.product.id === productid &&
            item.variation.baseColor === getbasecolour &&
            item.quantity === 1 && (
              <>
                {(checkingproduct = false)}
                <ButtonDiv key={key}>
                  <ButtonCLick
                    onClick={() => {
                      deleteProductfromCart(item.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} size="sm" />
                  </ButtonCLick>
                  <span className="counter-number">{item.quantity}</span>
                  <ButtonCLick
                    onClick={() => {
                      IncreaseProducttoCart(Number(item.quantity) + 1, item.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </ButtonCLick>
                </ButtonDiv>
              </>
            )
        )}

      {checkingproduct === true && (
        <>
          <ButtonDiv
            onClick={() => {
              addProducttoCart();
            }}
          >
            <ButtonCLick></ButtonCLick>
            <span className="counter-number">Add</span>
            <ButtonCLick>
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </ButtonCLick>
          </ButtonDiv>
        </>
      )}
    </>
  );
};

const ButtonDiv = styled.div`
  display: flex;
  background-color: black;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  margin-top: 1rem;
  border-radius: 200px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 36px;
  cursor: pointer;
  border-radius: 6px;
`;

const ButtonCLick = styled.div`
  border: none;
  background-color: transparent;
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  cursor: pointer;
`;

export default ProductButton;
