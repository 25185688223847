import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "../../Components/slider";
const url = process.env.REACT_APP_BASE_URL;

export default function Filters({
  setlocation,
  setminstipend,
  setmaxduration,
  setcategory,
  homecategory,
  homelocation,
}) {
  const [getlocation, setGetlocation] = useState([]);
  const [getmaxduration, setGetmaxduration] = useState([]);
  const [getcategory, setGetcategory] = useState([]);

  useEffect(() => {
    fetch(`${url}/z-internship-categories`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialLocation) => {
        setGetcategory(initialLocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/locations`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialLocation) => {
        setGetlocation(initialLocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/z-internship-durations`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialLocation) => {
        setGetmaxduration(initialLocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <Head>Filters</Head>
      <SubContainer1>
        <SubHead>Location</SubHead>
        <SubInputD onChange={(e) => setlocation(e.target.value)}>
          {getlocation.map((item, index) => (
            <option
              key={index}
              value={item.name}
              selected={item.name === homelocation}
            >
              {item.name}
            </option>
          ))}
        </SubInputD>
      </SubContainer1>
      <SubContainer2>
        <SubHead>Min Stipend per month</SubHead>
        <Slider color="#0074D9" minstipend={setminstipend} />
      </SubContainer2>
      <SubContainer3>
        <SubHead>Max.duration (Months)</SubHead>
        <SubInputD onChange={(e) => setmaxduration(e.target.value)}>
          {getmaxduration.map((item, index) => (
            <option key={index} value={item.months}>
              {item.months}
            </option>
          ))}
        </SubInputD>
      </SubContainer3>
      <SubContainer3>
        <SubHead>Category</SubHead>
        <SubInputD onChange={(e) => setcategory(e.target.value)}>
          {getcategory.map((item, index) => (
            <option
              key={index}
              value={item.name}
              selected={item.name === homecategory}
            >
              {item.name}
            </option>
          ))}
        </SubInputD>
      </SubContainer3>
    </Container>
  );
}
const Container = styled.div`
  width: 310px;
  height: 607px;
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.44);
  -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.44);
  border-radius: 10px;
  flex: 1;
  @media (max-width: 480px) {
    display: none;
    display: hidden;
  }
`;

const Head = styled.h3`
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  padding: 24px 108px 44px;
  @media (max-width: 480px) {
  }
`;
const SubInput = styled.input`
  padding: 5px 0;
  border-radius: 5pxs;
  width: 260px;
  height: 41px;
  @media (max-width: 480px) {
  }
`;
const SubInputD = styled.select`
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  border-radius: 5pxs;
  width: 260px;
  height: 41px;
  @media (max-width: 480px) {
  }
`;
const SubHead = styled.h3`
  font-size: 21px;
  font-weight: 400;
  padding: 0 0 3%;
  @media (max-width: 480px) {
  }
`;
const SubContainer1 = styled.div`
  padding: 10px 25px;

  @media (max-width: 480px) {
  }
`;

const SubContainer2 = styled.div`
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;

const SubContainer3 = styled.div`
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;
const CSubContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;

const Checkinput = styled.input`
  margin: 0 20px 5px;
  width: 20px;
  border-radius: 5px;
  height: 41px;
  @media (max-width: 480px) {
  }
`;
