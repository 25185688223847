import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;
const Aurl = process.env.REACT_APP_LAMDA_URL;

const sendMail = (email, name) => {
  fetch(`${Aurl}/default/sendContactForm`, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      receiverMail: email,
      subject:
        "Congratulations! You Have Successfully made an Intern account On SetuGram",
      receiverName: "admin",
      message: `Hi ${name},

Congratulations on making your Intern account on SetuGram. We are happy to have you on board with us!			
Let's first get you acquainted with some of the basic and important guidelines to be followed on SetuGram-
        1.Applying to internships and jobs on SetuGram is always free. You DO NOT have to pay anything to secure an internship or a job through us.
        2.Please Note: If an organization asks you to pay a security deposit or a fee for the internship or job then, please bring this to our attention by writing to us at contact@setugram.com, so that 
		we can look into it and take necessary actions.
        3.Please do not accept the internship or job if you are directly hired (without an interview or submitting an assignment) for it. The general process is that the employer takes an 
		interview or gives an assignment. And, based on your performance in that, you are selected or proceed to the next round of interviews. If you do get hired directly then, please bring it to our notice by writing to us at 
		contact@setugram.com
        4.Always ask for an offer letter or an email that confirms your hiring and mentions the terms and conditions of an internship or job from the employer before you start.\

Great! You're all caught up now and have familiarised yourself with the general community guidelines.

When your application is shared with the employer, you must be wondering about the next steps. For that, you need to wait for a few days. If the employer likes your application, he/she will get in touch with
you within 1-2 weeks and give you further details regarding the internship. You can also check your application status by logging into your SetuGram account.

Tip - In case you do not get any response or a status update regarding your application from the employer within 1-2 weeks of applying and your application status remains 'Applied', 'Seen' or 'Under 
Review' then, it is best that you apply for various other internships and/or jobs that are available on the platform. You may also get in touch with us to know how you can improve your applications which will, 
in turn, increase your chances of getting hired.
All the very best!
		
Regards,
Team SetuGram
https://www.setugram.com`,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

const sendOTPMail = (email, OTP) => {
  fetch(`${Aurl}/default/sendContactForm`, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      receiverMail: email,
      subject: "Password Reset",
      receiverName: "admin",
      message: `Dear user,

We have received a request to reset your account password. Please use the following One-Time Password (OTP) to reset your password:

OTP: ${OTP}

If you did not initiate this request, please ignore this email. Your password will remain unchanged.
		
Regards,
Team SetuGram
https://www.setugram.com`,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

const Signup = (user, setRegisterModalIsOpen, setIsLogged) => {
  axios
    .post(`${url}/auth/local/register`, {
      name: user.username,
      username: user.email,
      email: user.email,
      contact: user.contact,
      password: user.password,
    })
    .then((response) => {
      alert("User registered");
      if (response.data.jwt) {
        localStorage.setItem("setugramuser", response.data.user.id);
        localStorage.setItem("setugramuserEmailid", response.data.user.email);
        setRegisterModalIsOpen(false);
        setIsLogged(true);
        sendMail(response.data.user.email, response.data.user.name);
      }
    })
    .catch((Error) => {
      alert("Invalid Credentials or password");
      setRegisterModalIsOpen(true);
      setIsLogged(false);
    });
};

const Login = (user, setModalIsOpen, setIsLogged) => {
  const { identifier, password } = user;

  axios
    .post(`${url}/auth/local`, {
      identifier: identifier,
      password: password,
    })
    .then((response) => {
      if (response.data.jwt) {
        localStorage.setItem("setugramuser", response.data.user.id);
        localStorage.setItem("setugramuserEmailid", response.data.user.email);
        setModalIsOpen(false);
        setIsLogged(true);
      }
    })
    .catch((Error) => {
      setModalIsOpen(true);
      setIsLogged(false);
      alert("Invalid Credentials or password");
    });
};

const ConfirmEmailId = (
  email,
  setSecretCodeModalIsOpen,
  setForgotModalIsOpen,
  setSendOTPnumber
) => {
  axios
    .get(`${url}/users?email=${email}`)
    .then((response) => {
      if (response.data.statusCode === 400) {
        alert("Something went wrong, Please try again");
      }
      if (response.data.length > 0) {
        const userEmail = response?.data[0]?.email;
        const otp = Math.floor(100000 + Math.random() * 900000);
        sendOTPMail(userEmail, otp);
        setSendOTPnumber(otp);
        setSecretCodeModalIsOpen(true);
        setForgotModalIsOpen(false);
      } else {
        alert(
          "No user was found with the provided email address. Please proceed with the registration process."
        );
      }
    })
    .then((response) => response);
};

const ResetPassword = (user) => {
  const { userId, password } = user;
  axios
    .put(`${url}/users/${userId}`, {
      password: password,
    })
    .then((response) => {
      if (response.data.statusCode === 400) {
        alert("Something went wrong, Please try again");
      }
      if (response.data) {
      }
    })
    .then((response) => response);
};

const Logout = () => {
  localStorage.removeItem("setugramuser");
};

const userMe = (jwtToken) => {
  axios
    .get(`${url}/auth/local`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
    .then((response) => {})
    .then((response) => response);
};

const AddToCart = (token, userId, cart) => {
  const postData = { cart: cart };
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .post(`${url}/users/${userId}`, postData, axiosConfig)
    .then((response) => response);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Signup,
  Login,
  ResetPassword,
  ConfirmEmailId,
  Logout,
  userMe,
  AddToCart,
};
