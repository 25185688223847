import React from "react";
import styled from "styled-components";
export default function AboutCompany({ employer }) {
  return (
    <Wrapper>
      <Title>{employer}</Title>
    </Wrapper>
  );
}

const Info = styled.p`
  color: rgba(0, 0, 0, 0.51);
  font-size: 1.2em;
  margin-top: 0.4em;
  max-width: 1000px;
  @media (max-width: 480px) {
    font-size: 1.1em;
    font-weight: 500;
  }
`;

const Title = styled.h5`
  font-size: 1.5em;
  @media (max-width: 480px) {
    font-size: 1.1em;
    font-weight: 500;
  }
`;

const Wrapper = styled.div`
  margin-top: 4em;
  @media (max-width: 480px) {
    margin-top: 2em;
    padding: 0 0.5em;
  }
`;
