import React from "react";
import Title from "../../../../Components/Title";
import Category from "./category";
import Location from "./Location";
import Brand from "./brand";
import styled from "styled-components";
export default function index() {
  return (
    <Wrapper>
      <Title
        titleText="Setugram Career"
        subTitleText="Find and apply for the best suited Internships/Jobs for free"
        textSide="left"
      />
      <Category />
      <Location />
      <Brand />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media (min-width: 1440px) {
    /* width: 1440px; */
    margin: 0 auto;
  }
`;
