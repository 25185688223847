import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import Brandindex from "../../../../Components/Card/Brandindex";
import Brand1 from "../../../../assets/images/johargram.png";
import Brand2 from "../../../../assets/images/khadiwala.jpg";
import Brand3 from "../../../../assets/images/saurabhhain.jpg";
import Brand4 from "../../../../assets/images/Ptal Logo-1.png";
import Brand5 from "../../../../assets/images/aasmaan.jpg";
import Brand6 from "../../../../assets/images/sirohi.png";
import Brand7 from "../../../../assets/images/solo.png";
import Brand8 from "../../../../assets/images/upcycle.png";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export default function Brand() {
  const categories = [
    { Imageurl: Brand4 },
    { Imageurl: Brand1 },
    { Imageurl: Brand6 },
    { Imageurl: Brand2 },
    { Imageurl: Brand3 },
    { Imageurl: Brand5 },
    { Imageurl: Brand7 },
    { Imageurl: Brand8 },
  ];

  const title = "Brands On Board";

  return (
    <Wrapper>
      <TitleDiv2>
        <H4>{title}</H4>
      </TitleDiv2>
      <CategoryList>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={10000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          containerStyle={{ paddingTop: "20px" }}
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
        >
          {categories.length > 0
            ? categories.map((Category, index) => (
                <Brandindex key={index} imageLink={`${Category.Imageurl}`} />
              ))
            : null}
        </Carousel>
      </CategoryList>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  margin-top: 4em;
  @media (max-width: 480px) {
    margin-top: 2em;
  }
`;

const H4 = styled.h4`
  font-size: 1.4em;
  font-weight: normal;
`;

const TitleDiv2 = styled.div`
  align-items: center;
  text-align: center;
  justify-content: space-between;
`;

const CategoryList = styled.div`
  margin-top: 2.5em;
  @media (max-width: 480px) {
    margin-top: 1.5em;
  }
`;
