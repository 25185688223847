import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Nav,
  NavLink,
  NavLinkX,
  NavMenu,
  CartItem,
  DIV,
  DivC,
  NavLinkEm,
  NavBtnLink,
  ProfileWrapper,
} from "./NavbarElements";
import Logo from "../logo";
import LoginModal from "../shared/LoginModal";
import RegisterModal from "../shared/RegisterModal";
import Modal from "react-modal";
import NavbarMobile from "../NavbarMobile/";
import Toggle from "../NavbarMobile/Toggle";
import { BsCart3 } from "react-icons/bs";
import { IoMdContact } from "react-icons/io";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import { useItem } from "../Context/useContext";
import ForgotPasswordModal from "../shared/ForgotPasswordModal";
import SecretCodeModal from "../shared/SecretCodeModal";
import ResetPasswordModal from "../shared/ResetPasswordModal";

Modal.setAppElement("#root");

const Navbar = () => {
  const { productChange } = useItem();

  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [RegisterModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [ForgotModalIsOpen, setForgotModalIsOpen] = useState(false);
  const [SecretCodeModalIsOpen, setSecretCodeModalIsOpen] = useState(false);
  const [ResetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    useState(false);
  const [SendOTPnumber, setSendOTPnumber] = useState(0);

  const history = useHistory();

  const [windowDimension, setWindowDimension] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(0);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function timeFunction() {
    setTimeout(function () {
      setModalIsOpen(true);
    }, 15000);
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // Checking localStorage changes

  useEffect(() => {
    setCurrentItem(localStorage.getItem("TcartItem"));
  }, [productChange]);

  useEffect(() => {
    if (localStorage.getItem("setugramuser")) {
      setIsLogged(true);
    } else {
      timeFunction();
    }
  }, [isLogged]);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isMobile = windowDimension <= 640;
  const [navToggled, setNavToggled] = useState(false);
  const handleNavToggle = () => {
    setNavToggled(!navToggled);
  };
  return (
    <>
      <Nav>
        <div style={{ display: "flex" }}>
          <Logo />
          <NavLinkX>Building Connections</NavLinkX>
        </div>
        {navToggled ? <NavbarMobile handleNavToggle={handleNavToggle} /> : null}
        {isMobile ? <Toggle handleNavToggle={handleNavToggle} /> : null}
        <NavMenu>
          <NavLink to="/" exact>
            Home
          </NavLink>
          <NavLink to="/internships" exact>
            Career
          </NavLink>
          <NavLink to="/products" exact>
            Products
          </NavLink>
          <NavLink to="/courses" exact>
            Courses
          </NavLink>
          <NavLink to="/#about" exact>
            About
          </NavLink>
          <NavLink to="/#contact" exact>
            Contact
          </NavLink>
          <NavLinkEm href="https://employer.setugram.com/" exact>
            Employer
          </NavLinkEm>
        </NavMenu>

        {isLogged ? (
          <ProfileWrapper>
            <Link to="/cart">
              <DivC>
                <BsCart3 style={{ position: "relative " }} size={30} />
                <CartItem>
                  {currentItem > 0 && (
                    <span className="item-count ml-1">{currentItem}</span>
                  )}
                  {currentItem === 0 && (
                    <span className="item-count ml-1">0</span>
                  )}
                </CartItem>
              </DivC>
            </Link>
            <DIV>
              <IconButton
                className="IconPros"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                style={{ padding: ".2rem", margin: "0 1em 0" }}
                onClick={handleToggle}
              >
                <IoMdContact size={50} />
                {/* <IMG src={defaultPic} alt='Setugram logo'></IMG> */}
              </IconButton>
            </DIV>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <Link
                          to="/profilepage"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={handleToggle}
                        >
                          <MenuItem>My Profile</MenuItem>
                        </Link>
                        <Link
                          to="/address"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={handleToggle}
                        >
                          <MenuItem>My Address</MenuItem>
                        </Link>
                        <Link
                          to="/myinternship"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={handleToggle}
                        >
                          <MenuItem>My Internship</MenuItem>
                        </Link>
                        <Link
                          to="/coursespage"
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={handleToggle}
                        >
                          <MenuItem>My Courses</MenuItem>
                        </Link>
                        <MenuItem
                          onClick={() => {
                            localStorage.removeItem("setugramuser");
                            setIsLogged(false);
                            history.push("/");
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ProfileWrapper>
        ) : (
          <NavBtnLink onClick={() => setModalIsOpen(true)}>Login</NavBtnLink>
        )}
        <LoginModal
          ModalIsOpen={ModalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setIsLogged={setIsLogged}
          setRegisterModalIsOpen={setRegisterModalIsOpen}
          setForgotModalIsOpen={setForgotModalIsOpen}
        />
        <RegisterModal
          RegisterModalIsOpen={RegisterModalIsOpen}
          setRegisterModalIsOpen={setRegisterModalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setIsLogged={setIsLogged}
        />
        <ForgotPasswordModal
          ForgotModalIsOpen={ForgotModalIsOpen}
          setForgotModalIsOpen={setForgotModalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setSecretCodeModalIsOpen={setSecretCodeModalIsOpen}
          setSendOTPnumber={setSendOTPnumber}
        />
        <SecretCodeModal
          SecretCodeModalIsOpen={SecretCodeModalIsOpen}
          setSecretCodeModalIsOpen={setSecretCodeModalIsOpen}
          SendOTPnumber={SendOTPnumber}
          setResetPasswordModalIsOpen={setResetPasswordModalIsOpen}
        />
        <ResetPasswordModal
          ResetPasswordModalIsOpen={ResetPasswordModalIsOpen}
          setResetPasswordModalIsOpen={setResetPasswordModalIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      </Nav>
    </>
  );
};

export default Navbar;
