import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
export default function sectionTitle({ title, link, apply }) {
  const linkTo = `${link}`;
  return (
    <TitleDiv>
      <H4>{title}</H4>
      {link !== "none" && <LinkS to={linkTo}>{apply} &gt;</LinkS>}
    </TitleDiv>
  );
}

const H4 = styled.h4`
  font-size: 1.4em;
  font-weight: normal;
`;

const LinkS = styled(Link)`
  text-decoration: none;
  height: 19px;
  color: #000000;
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
