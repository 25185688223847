import React, { useEffect } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import MainImage from "../../assets/images/Whoweare.svg";
export default function WhoAreWe() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem === "about") {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <Wrapper id="about">
      <WrapperXX>
        <Title>Who we are?</Title>
        <Wrapper2>
          <ImageWrapper>
            <Img src={MainImage} alt="Main"></Img>
          </ImageWrapper>
          <InfoWrapper>
            <Info>
              Setugram is India's first Niche-based Internship & Job Board for
              Designers. We identify the gap in the Design Industry between the
              companies and the Design students. Companies, Brands, export
              houses, and designers want to hire interns and freshers but do not
              find well-trained applicants, and going through lots of portfolios
              and resumes is a tedious process. Setugram bridges this gap by
              having Industry experts train and mentor the candidates and get
              them industry-ready. On signing up with Setugram, companies will
              have an enormous database of candidates. Alternatively, we can
              help them save their time, money, and energy by providing them
              with trained and screened candidates as per the required skill
              sets without putting a dent in their recruiting budget.
            </Info>
          </InfoWrapper>
        </Wrapper2>
      </WrapperXX>
    </Wrapper>
  );
}
const WrapperXX = styled.div`
  /* display: flex;
	justifyContent : space-between; */
  @media (max-width: 480px) {
    display: block;
  }
`;
const Wrapper2 = styled.div`
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justifycontent: space-between;
  @media (max-width: 480px) {
    display: block;
  }
`;
const Img = styled.img`
  position: absolute;
  z-index: 10;
  top: 5%;
  @media (max-width: 480px) {
    width: 100%;
    position: relative;
    padding: 0 10%;
    bottom: 50px;
    bottom: 0;
    left: 0;
  }
`;
const Title = styled.h3`
  text-align: center;
  font-size: 3em;
  line-height: 58px;
  @media (max-width: 480px) {
    font-size: 21px;
    line-height: 23px;
  }
`;

const Info = styled.p`
  margin-top: 2em;
  font-style: normal;
  font-weight: normal;
  font-size: 1.15em;
  color: rgba(0, 0, 0, 0.51);
  line-height: 26px;
  @media (max-width: 480px) {
    font-size: 14px;
    margin-top: 1em;
    line-height: 15px;
    flex: 1;
  }
`;

const InfoWrapper = styled.div`
  width: 50vw;
  height: 100%;
  padding: 0 0 0;

  text-align: justify;
  @media (max-width: 480px) {
    padding: 8em 0 0 1em;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 50vw;
  height: 100%;
  position: relative;
  padding: 0;
  @media (max-width: 480px) {
    padding: 0 0 0 17px;
    height: 50vw;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 5em 0;
  height: 700px;
  @media (max-width: 480px) {
    padding: 5em 0;
  }
`;
