import React from "react";
import { Link } from "react-router-dom";

const Successfull = () => {
  const finalTotal = localStorage.getItem("final_total");

  window.scrollTo(0, 0);

  return (
    <>
      <body className="bg-blue-100" style={{ height: "100vh" }}>
        <main
          className="h-screen flex justify-center items-center"
          style={{
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
            marginTop: "20vh",
          }}
        >
          <div className="bg-white p-10 md:p-20 rounded">
            <div className="text-center" style={{ color: "green" }}>
              <svg
                className="fill-current text-teal-500 inline-block h-40 w-40"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div className="text-center">
              <h3 className="mt-3 text-4xl font-bold">Order Placed</h3>
              <br />
              <span style={{ marginTop: "1rem" }}>₹{finalTotal}</span>
              <br />
              <span style={{ marginTop: "2rem" }}>
                Thank you for your purchase
              </span>
              <br />
              <Link to="/">
                <a
                  className="font-bold"
                  style={{ color: "red" }}
                  style={{ marginTop: "1rem" }}
                >
                  CONTINUE SHOPPING
                </a>
              </Link>
            </div>
          </div>
        </main>
      </body>
    </>
  );
};

export default Successfull;
