import React, { useState, useEffect } from "react";
import {
  InterWrapper,
  Title,
  ButtonWrapper,
} from "../Internships/InternshipInfoElements";
import axios from "axios";
import { BiDownload } from "react-icons/bi";
import Swal from "sweetalert2";
import Button from "../../Components/Button";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ResumePopup from "./ResumePopup";
import Portfolio from "./Portfolio";

const url = process.env.REACT_APP_BASE_URL;

export default function ProfileInfo({ Internship }) {
  const USER_ID = localStorage.getItem("setugramuser");
  const [getuserdetail, setgetUserDetail] = useState([]);
  const [tags, setTags] = useState([]);
  const [applynowisopen, setApplyNowIsOpen] = useState(false);
  const [portfolioisopen, setPortfolioIsOpen] = useState(false);

  const handleClose = () => {
    setApplyNowIsOpen(false);
  };
  const handleClose2 = () => {
    setPortfolioIsOpen(false);
  };

  const [setname, setSetName] = useState("");
  const [setemail, setSetEmail] = useState("");
  const [setcontact, setSetContact] = useState("");
  const [setlinkedind, setSetLinkedin] = useState("");
  const [setwebsite, setWebsite] = useState("");
  const [setinstagram, setIntagram] = useState("");
  const [setportfolio, setPortfolio] = useState("");
  const [settags, setSetTags] = useState([]);
  const [updatebutton, setUpdateButton] = useState(false);
  const [filename, setFileName] = useState("");
  const [filenameTwo, setFileNameTwo] = useState("");

  useEffect(() => {
    fetch(`${url}/users/${USER_ID}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getdetails) => {
        setgetUserDetail(getdetails);
        setSetTags(
          getdetails.skills.map((item) => {
            return item.id;
          })
        );
        setSetName(getdetails.name);
        setSetEmail(getdetails.email);
        setSetContact(getdetails.contact);
        setSetLinkedin(getdetails.linkedInProfile);
        setPortfolio(getdetails.PortfolioURL);
        const filename = getdetails?.portfolioS3Url.split("/").pop();
        const filenameT = getdetails?.resumeS3Url.split("/").pop();
        setFileName(filename);
        setFileNameTwo(filenameT);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [applynowisopen, portfolioisopen]);

  useEffect(() => {
    fetch(`${url}/z-tags-internships`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const updateForm = () => {
    axios
      .put(`${url}/users/${USER_ID}`, {
        username: setemail,
        email: setemail,
        linkedInProfile: setlinkedind,
        website: setwebsite,
        instagram: setinstagram,
        name: setname,
        contact: setcontact,
        skills: settags,
        portfolioURL: setportfolio,
      })
      .then((response) => {
        if (response.status == 200) {
          Swal.fire("Done", "Your profile is updated successfully!", "success");
        } else {
          Swal.fire("Failed", "There is something problem!", "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <InterWrapper>
        <Title>Profile </Title>
        {getuserdetail && (
          <div style={{ marginTop: "2rem" }}>
            <label htmlFor="fInput">Name</label>
            <br />
            <input
              defaultValue={getuserdetail.name}
              onChange={(e) => {
                setSetName(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">Email</label>
            <br />
            <input
              defaultValue={getuserdetail.email}
              onChange={(e) => {
                setSetEmail(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">Mobile Number</label>
            <br />
            <input
              defaultValue={getuserdetail.contact}
              onChange={(e) => {
                setSetContact(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <br />
            <div className="appId-subcon1">
              <p className="appId-head col-sm-2">Skills :</p>
              {getuserdetail.skills && (
                <Autocomplete
                  defaultValue={getuserdetail.skills}
                  multiple
                  id="tags-outlined"
                  options={tags}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    setSetTags(
                      newValue.map((ele) => {
                        return ele.id;
                      })
                    );
                    setUpdateButton(true);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Search ..."
                      placeholder="Favorites"
                    />
                  )}
                />
              )}
            </div>
            <label htmlFor="fInput">Linkedin URL</label>
            <br />
            <input
              id="textarea"
              type="text"
              defaultValue={getuserdetail.linkedInProfile}
              onChange={(e) => {
                setSetLinkedin(e.target.value);
                setUpdateButton(true);
              }}
            ></input>
            <br />
            <label htmlFor="fInput">Website URL (optional)</label>
            <br />
            <input
              id="textarea"
              type="text"
              defaultValue={getuserdetail.website}
              onChange={(e) => {
                setWebsite(e.target.value);
                setUpdateButton(true);
              }}
            ></input>
            <br />
            <label htmlFor="fInput">Instagram URL (optional)</label>
            <br />
            <input
              id="textarea"
              type="text"
              defaultValue={getuserdetail.instagram}
              onChange={(e) => {
                setIntagram(e.target.value);
                setUpdateButton(true);
              }}
            ></input>
            <br />
            <label htmlFor="fInput">PortFolio URL (optional)</label>
            <br />
            <input
              id="textarea"
              type="text"
              defaultValue={getuserdetail.portfolioURL}
              onChange={(e) => {
                setPortfolio(e.target.value);
                setUpdateButton(true);
              }}
            ></input>
            <br />
            <div className="appId-subcon4 row">
              <div onClick={() => setApplyNowIsOpen(true)}>
                <button className="appId-liB">Upload Resume (PDF)</button>
                <br />
                <span
                  className="borderbox-uploaded"
                  style={{ fontSize: "11px" }}
                >
                  upload size up to 5MB
                </span>
              </div>
              {getuserdetail?.resumeS3Url && (
                <div className="borderboxTwo">
                  <div className="borderbox-inner">
                    <div>
                      <span>{filenameTwo}</span>
                    </div>
                    <a href={`${getuserdetail?.resumeS3Url}`} target="_blank">
                      <BiDownload
                        style={{ cursor: "pointer", marginTop: "10px" }}
                        size={25}
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="appId-subcon4 row">
              <div onClick={() => setPortfolioIsOpen(true)}>
                <button className="appId-liB">Upload PortFolio (PDF)</button>
                <br />
                <span
                  className="borderbox-uploaded"
                  style={{ fontSize: "11px" }}
                >
                  upload size up to 5MB
                </span>
              </div>
            </div>
            {getuserdetail?.portfolioS3Url && (
              <div className="borderbox">
                <div className="borderbox-inner">
                  <div>
                    <span>{filename}</span>
                  </div>
                  <a href={`${getuserdetail?.portfolioS3Url}`} target="_blank">
                    <BiDownload
                      style={{ cursor: "pointer", marginTop: "10px" }}
                      size={25}
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
        {updatebutton === true && (
          <ButtonWrapper onClick={() => updateForm()}>
            <Button Name="Update Profile" />
          </ButtonWrapper>
        )}
      </InterWrapper>
      <ResumePopup
        addAddressIsOpen3={applynowisopen}
        handleClose3={handleClose}
        internshipid={Internship.id}
      ></ResumePopup>
      <Portfolio
        addAddressIsOpen3={portfolioisopen}
        handleClose3={handleClose2}
        internshipid={Internship.id}
      ></Portfolio>
    </>
  );
}

const ButtonDiv = styled.button`
  display: flex;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  margin-left: 0.5rem;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.2em;
  background-color: #fff56c;
`;
