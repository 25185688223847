import Styled from "styled-components";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FeaturedCourse from "./FeaturedCourse";

const url = process.env.REACT_APP_BASE_URL;

export default function FeaturedSection() {
  const [featuredproduct, setFeaturedProduct] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/courses?isFeatured=true`)
      .then((response) => {
        setFeaturedProduct(response.data);
      })
      .then((response) => response);
  }, []);

  let courseDetailPage = "";

  if (featuredproduct.length !== 0) {
    courseDetailPage = featuredproduct[0].id;
  }

  return (
    <Wrapper>
      <Title>Featured Courses</Title>
      <FeaturedCourse />
    </Wrapper>
  );
}
const Wrapper = Styled.section`
    margin-top: 1.5em;
    padding-bottom: 1em;
`;
const Title = Styled.h4`
    font-size: 1.5em;
    line-height: 1.8em;
    @media (max-width: 480px) {
        font-size: 21px;
    }
`;
