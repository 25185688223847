import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ButtonAdd from "../../../Components/ButtonAddCart";
import CustomizeButton from "../../../Components/Customize";
import {
  Wrapper,
  UpperSection,
  LowerSection,
  AddToCartWrapper,
  InfoWrapperI,
  Price,
  ProductInfoDiv,
  Name,
  Info,
  Label,
  VariantDiv,
  VariationLabel,
  IMG,
  ImgDiv,
} from "./ProductInfoElements";
import ECommerceServices from "../../../ApiServices/products";
import ProductButton from "./ProductAddButton";
import ReactMarkdown from "react-markdown";
import Customise from "./customise";

const url = process.env.REACT_APP_BASE_URL;
//
export default function ProductInfo({ id }) {
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [Product, setProduct] = useState({ variations: [] });
  const [variantSrc, setVariantSrc] = useState(null);
  const [getbasecolour, setGetBaseColour] = useState(null);
  const [getthreadcolour, setGetthreadColour] = useState(null);
  const [designstyle, setDesignStyle] = useState(null);
  const [choosevariant, setChooseVariant] = useState();
  const user = localStorage.getItem("setugramuser");

  const fetching = () => {
    ECommerceServices.getProductById(id)
      .then((initialProduct) => {
        setProduct(initialProduct.data);
        setChooseVariant(initialProduct.data.variations[0]);
        setVariantSrc(`${initialProduct.data.variations[0].image.url}`);
        setGetBaseColour(initialProduct.data.variations[0].baseColor);
        setGetthreadColour(initialProduct.data.variations[0].weftThreadColor);
        setDesignStyle(initialProduct.data.variations[0].designStyle);
      })
      .catch((err) => {});
  };

  useEffect(fetching, [id]);
  const handleVariantChange = (e) => {
    const src = e.target.value;
    setVariantSrc(`${src}`);
  };

  const { name, price, description, variations } = Product;

  return (
    <Wrapper>
      <UpperSection>
        <ImgDiv>
          <IMG src={variantSrc} alt={name}></IMG>
        </ImgDiv>
        <ProductInfoDiv>
          <Name>{name}</Name>
          <Info>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Info>
          <VariantDiv onChange={handleVariantChange}>
            <VariationLabel>Select Variations: </VariationLabel>
            {variations.map((variant, index) => {
              return (
                <>
                  <input
                    key={index}
                    type="radio"
                    defaultChecked={index === 0 ? true : false}
                    value={variant.image.url}
                    onChange={() => {
                      setChooseVariant(variant);
                      setGetBaseColour(variant.baseColor);
                      setGetthreadColour(variant.weftThreadColor);
                      setDesignStyle(variant.designStyle);
                    }}
                    name="variants-Selection"
                  />
                  <Label>{index + 1}</Label>
                </>
              );
            })}
          </VariantDiv>
          <InfoWrapperI>
            <p>Base-color: </p>
            <p> {getbasecolour}</p>
          </InfoWrapperI>
          <InfoWrapperI>
            <p>Weft Thread Color: </p>
            <p> {getthreadcolour}</p>
          </InfoWrapperI>
          <InfoWrapperI>
            <p>Design Style: </p>
            <p> {designstyle}</p>
          </InfoWrapperI>
        </ProductInfoDiv>
      </UpperSection>
      <LowerSection>
        <AddToCartWrapper>
          <Price>Rs.{price}</Price>
          {user ? (
            <ButtonDiv>
              <ProductButton
                productid={Number(id)}
                productvariant={choosevariant}
                getbasecolour={getbasecolour}
              />
            </ButtonDiv>
          ) : (
            <ButtonDiv onClick={() => alert("Please Login")}>
              <ButtonAdd Name="Add to Cart"></ButtonAdd>
            </ButtonDiv>
          )}

          <>
            {user ? (
              <ButtonDiv
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                <CustomizeButton Name="Customize"></CustomizeButton>
              </ButtonDiv>
            ) : (
              <ButtonDiv onClick={() => alert("Please Login")}>
                <CustomizeButton Name="Customize"></CustomizeButton>
              </ButtonDiv>
            )}
            <Customise
              ModalIsOpen={ModalIsOpen}
              setModalIsOpen={setModalIsOpen}
              productName={name}
              id={id}
            />
          </>
        </AddToCartWrapper>
      </LowerSection>
    </Wrapper>
  );
}

const ButtonDiv = styled.div`
  padding: 1em 3em 0;
  @media (max-width: 480px) {
    padding: 1em;
  }
`;
