import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import PopularECommerce from "../../Components/shared/PopularItemList";
import PopularCourses from "../../Components/shared/PopularCoursesList";
import Items from "./Items";

function Index() {
  const [product, setProduct] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    localStorage.setItem("TcartItem", Number(product) + Number(courses));
  }, [product, courses]);

  return (
    <Container>
      <TitleWrapper>
        <Title>Shopping Cart</Title>
      </TitleWrapper>
      <Items setProduct={setProduct} setCourses={setCourses} />
      {product.length > 0 && (
        <PopularECommerce title="You might also like in Products" />
      )}
      {courses.length > 0 && (
        <PopularCourses title="You might also like in courses" />
      )}
    </Container>
  );
}

export default Index;

const TitleWrapper = Styled.div`
    background-color: #FFF56C;
    height: 140px;
    padding: 3em 2em;
    @media (max-width: 480px) {
        height: 74px;
        padding-top: 40px;
        padding-left: .5em;
        padding-bottom: 1.5em;
    }
`;

const Container = Styled.div`
    margin-top: 4em;
    height: 90vh;
    @media (max-width: 480px) {
        padding: 1em;
    }
`;

const Title = Styled.h1`
    font-size: 2em;
    font-weight: 500;
    @media (max-width: 480px) {
        font-size: 21px;
    }
`;
