import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import Courseindex from "../../../../Components/Card/Courseindex";
import coursesServices from "../../../../ApiServices/courses";
const url = process.env.REACT_APP_BASE_URL;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export default function Category() {
  const [categories, setcategories] = useState([]);

  const fetch = () => {
    coursesServices.getCategories().then((initialCategories) => {
      setcategories(initialCategories.data);
    });
  };
  useEffect(fetch, []);

  return (
    <Wrapper>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={10000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
      >
        {categories.length > 0 &&
          categories.map((Category, index) => (
            <Courseindex
              key={index}
              CardTitle={Category.name}
              link="#"
              imageLink={`${Category?.image?.url}`}
            />
          ))}
      </Carousel>
    </Wrapper>
  );
}
//
const Wrapper = styled.div`
  margin-top: 2.5em;
  @media (max-width: 480px) {
    margin-top: 2em;
  }
`;

const CategoryList = styled.div`
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;
  align-content: center;
  @media (max-width: 480px) {
    margin-top: 1.5em;
    overflow-x: scroll;
    gap: 1em;
  }
`;
