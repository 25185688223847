import React from "react";
import styled from "styled-components";

export default function Button({ Name, bg_color, ft_color, handleClick }) {
  return (
    <ButtonDiv bg_color={bg_color} ft_color={ft_color} onClick={handleClick}>
      {Name}
    </ButtonDiv>
  );
}

const ButtonDiv = styled.button`
  display: flex;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  justify-content: center;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.2em;
  background-color: #fff56c;
`;
