import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import "../../../src/App.css";
import CourseServices from "../../ApiServices/courses";
import CourseCardP from "./CourseCardP";

export default function PopularCourses({ title }) {
  let toDisplay;
  if (title === "none") {
    toDisplay = "none";
  }
  const [courses, setCourses] = useState([]);
  const fetch = () => {
    CourseServices.getAllPopularCourses()
      .then((initialCourses) => {
        setCourses(initialCourses.data);
      })
      .catch((err) => {});
  };
  useEffect(fetch, []);
  const setting = {
    breakPoints: [
      { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
      { width: 850, itemsToShow: 4, itemsToScroll: 1, pagination: false },
      {
        width: 1080,
        itemsToShow: 5,
        itemsToScroll: 1,
        pagination: true,
      },
    ],
    itemPadding: [0, 10],
  };
  //
  return (
    <Container>
      <TitleWrapper toDisplay={toDisplay}>
        <Title>{title}</Title>
        <HorizontalLine></HorizontalLine>
      </TitleWrapper>
      <Wrapper className="styling-example">
        <Carousel {...setting}>
          {courses.map((course) => (
            <CourseCardP key={course.id} course={course} />
          ))}
        </Carousel>
      </Wrapper>
    </Container>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2em;
`;

const Container = styled.div`
  margin-top: 2em;
  padding: 0 5em 3em;
  width: 100%;
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    padding: 0 0.5em;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  display: ${(props) => props.toDisplay};
`;
const Title = styled.h5`
  font-size: 1.4em;
  line-height: 1em;
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 19px;
  }
`;
const HorizontalLine = styled.div`
  border-top: 1.5px solid rgba(0, 0, 0, 0.2);
  flex: 1;
`;
