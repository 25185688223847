import React, { useState } from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
export default function CourseCardP({ course }) {
  const [windowSize, setWindowSize] = useState(
    window.innerWidth > 500 ? true : false
  );

  window.addEventListener("resize", function () {
    if (window.innerWidth >= 500) {
      setWindowSize(true);
    } else {
      setWindowSize(false);
    }
  });

  const { id, name, price, images } = course;
  let src;
  if (images.length === 0) {
    src = "";
  } else {
    src = images[0].url;
  }
  src = `${src}`;
  return (
    <Wrapper>
      {windowSize === true && (
        <LinkS to={`/courses/${id}`} target="_blank" rel="noopener noreferrer">
          <Div>
            <ImgDiv>
              <IMG src={src} alt={name}></IMG>
            </ImgDiv>
            <NameW>{name}</NameW>
            <PriceW>Rs.{price}</PriceW>
          </Div>
        </LinkS>
      )}
      {windowSize === false && (
        <LinkS to={`/courses/${id}`} target="_self" rel="noopener noreferrer">
          <Div>
            <ImgDiv>
              <IMG src={src} alt={name}></IMG>
            </ImgDiv>
            <NameW>{name}</NameW>
            <PriceW>Rs.{price}</PriceW>
          </Div>
        </LinkS>
      )}
    </Wrapper>
  );
}

const ImgDiv = Styled.div`
    height: 155px; 
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    @media (max-width: 480px) {
        /* width: 115px; */
        height: 97px;
    }
`;

const Wrapper = Styled.div`
    margin-bottom: 20px;
    margin: 10px;
    @media (max-width: 480px) {
        /* width: 43.7%; */
        /* width: 10.67em; */
    }
`;

const Div = Styled.div`
    width: 216px;
    height: 260px;
    font-size: 1.3em;
    font-weight: 400;
    height: 100%;
    box-shadow: 0px 0px 6px -2px rgb(0 0 0 / 44%);
    -webkit-box-shadow: 0px 0px 6px -2px rgb(0 0 0 / 44%);
    -moz-box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.44);
    @media (max-width: 480px) {
        font-size: 1em;
        width: 100%;
        /* width: 10.67em; */
    }
`;

const LinkS = Styled(Link)`
    text-decoration: none;
    color: #000;
    @media (max-width: 480px) {
        width: 100% !important;
        /* width: 10.67em; */
    }
`;

const IMG = Styled.img`
    height: 155px;
    object-fit: cover;  
    width: 100%;
    @media (max-width: 480px) {
        height: 100%;
        width: 100%;
    }
`;

const NameW = Styled.h5`
    width: 100%;
    height: 85px;
    padding: 5px;
    font-size: 21px;
    font-weight: normal;
    margin-top: .2em;
    line-height: 25px;
    @media (max-width: 480px) {
        margin-top: 0;
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
    }
`;
const PriceW = Styled.p`
    line-height: 25px;
    margin-top: .4em;
    padding-left: 5px;
    font-size: 21px;
    @media (max-width: 480px) {
        line-height: 18px;
        margin-top: 0em;
        font-size: 14px;
    }
`;
