import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 0;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

export const Button = styled.button`
  padding: 0.4em 1em;
  outline: none;
  border: none;
  color: #fff;
  background-color: #000;
  font-size: 21px;
  @media (max-width: 480px) {
    font-size: 16px;
    padding: 0.2em 0.8em;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
`;
