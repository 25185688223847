import React from "react";
import "./App.css";
import Internships from "./pages/Internships";
import InternshipDetails from "./pages/Internships/InternshipDetails";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ECommerce from "./pages/ECommerce";
import ProductDetails from "./pages/ECommerce/Components/ProductDetails";
import Courses from "./pages/Courses";
import Login from "./Components/shared/LoginModal";
import Home from "./pages/Home";
import CourseDetails from "./pages/Courses/Components/CourseDetails";
import Cart from "./pages/Cart";
import Navbar from "./Components/Navbar";
import Signup from "./pages/Signup";
import Footer from "./Components/shared/Footer";
import OrderPage from "./pages/Profile/Order/OrderPage";
import Address from "./pages/Profile/Address/address";
import Profile from "./pages/Profile/Profile";
import CoursesPage from "./pages/Profile/Courses/CoursesPage";
import PrivateRoute from "./Components/ProtectedRoutes";
import { ItemContextProvider } from "./Components/Context/useContext";
import Customise from "./pages/ECommerce/Components/customise";
import { Context } from "./Components/context";
import Failure from "./pages/ResponsePage/Failure";
import Successfull from "./pages/ResponsePage/Successfull";
import PrivatePolicy from "./Components/shared/PrivatePolicy";
import TernofUse from "./Components/shared/TernofUse";
import RefundPolicy from "./Components/shared/RefundPolicy";
import MyInternship from "./pages/Internships/UserInternship/myInternship";

const App = () => {
  return (
    <Context>
      <BrowserRouter>
        <ItemContextProvider>
          <Navbar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/internships" exact component={Internships} />
            <Route
              path="/internships/:id"
              exact
              component={InternshipDetails}
            />
            <Route path="/course/:categoryname" exact component={Courses} />
            <Route
              path="/internship/:categoryname"
              exact
              component={Internships}
            />
            <Route
              path="/internshiplocation/:location"
              exact
              component={Internships}
            />
            <Route path="/product/:categoryname" exact component={ECommerce} />
            <Route path="/products" exact component={ECommerce} />
            <Route path="/products/:id" exact component={ProductDetails} />
            <Route path="/courses" exact component={Courses} />
            <Route path="/courses/:id" exact component={CourseDetails} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/private-policy" component={PrivatePolicy} />
            <Route path="/term-of-use" component={TernofUse} />
            <Route path="/refund-policy" component={RefundPolicy} />
            <Route path="/product/customise" component={Customise} />
            <PrivateRoute path="/cart">
              <Cart></Cart>
            </PrivateRoute>
            <PrivateRoute path="/orderpage">
              <OrderPage></OrderPage>
            </PrivateRoute>
            <PrivateRoute path="/coursespage">
              <CoursesPage></CoursesPage>
            </PrivateRoute>
            <PrivateRoute path="/profilepage">
              <Profile></Profile>
            </PrivateRoute>
            <PrivateRoute path="/myinternship">
              <MyInternship></MyInternship>
            </PrivateRoute>
            <PrivateRoute path="/address">
              <Address></Address>
            </PrivateRoute>
            <PrivateRoute exact path="/payment_successfull">
              <Successfull></Successfull>
            </PrivateRoute>
            <PrivateRoute exact path="/payment_failure">
              <Failure></Failure>
            </PrivateRoute>
            <Route path="*" exact component={Home} />
          </Switch>
          <Footer />
        </ItemContextProvider>
      </BrowserRouter>
    </Context>
  );
};

export default App;
