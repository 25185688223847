import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import ButtonAdd from "../../../Components/ButtonAddCart";
import { FaFileAlt } from "react-icons/fa";
import { CgInfinity } from "react-icons/cg";
import { BsPhoneFill } from "react-icons/bs";
import { useItem } from "../../../Components/Context/useContext";
const url = process.env.REACT_APP_BASE_URL;

export default function PurchaseSection({ course }) {
  const { changeValue } = useItem();
  const { id, price, images, name } = course;
  const USER_ID = localStorage.getItem("setugramuser");
  const [getCourseQuantity, setGetCourseQuantity] = useState([]);
  const [qty, setQty] = useState(false);
  const totalItem = localStorage.getItem("TcartItem");

  const changeic = () => {
    if (qty === true) {
      setQty(false);
    } else {
      setQty(true);
    }
  };

  let src;
  if (images !== undefined && images.length === 0) {
    src = "";
  } else if (images !== undefined) {
    src = images[0].url;
  }
  src = `${src}`;

  var checkingproduct = true;

  useEffect(() => {
    axios
      .get(`${url}/z-course-orders?users.id=${USER_ID}&_status=InCart`)
      .then((response) => {
        setGetCourseQuantity(response.data);
      })
      .then((response) => response);
  }, [qty]);

  const addCourse = () => {
    axios
      .post(`${url}/z-course-orders`, {
        status: "InCart",
        course: id,
        users: [`${USER_ID}`],
      })
      .then((response) => {
        localStorage.setItem("TcartItem", Number(totalItem) + 1);
        changeValue();
        changeic();
      })
      .then((response) => response);
  };

  return (
    <Cont>
      <Wrapper>
        <ImgDiv>
          <Image src={src} alt={name}></Image>
        </ImgDiv>
        <Price>Rs. {price}</Price>
        <Info>
          <FaFileAlt /> Certificate of Completion
        </Info>
        <Info>
          <CgInfinity /> Weekend batches
        </Info>
        <Info>
          <BsPhoneFill /> Exclusive perk and Discount offers
        </Info>
        {getCourseQuantity &&
          getCourseQuantity.map(
            (item, key) =>
              item.course.id === id && (
                <>
                  {(checkingproduct = false)}
                  <ButtonDiv key={key}>
                    <ButtonAdd Name="Added"></ButtonAdd>
                  </ButtonDiv>
                </>
              )
          )}
        {checkingproduct === true && USER_ID && (
          <ButtonDiv
            onClick={() => {
              addCourse();
            }}
          >
            <ButtonAdd Name="Add to Cart"></ButtonAdd>
          </ButtonDiv>
        )}
        {!USER_ID && (
          <ButtonDiv onClick={() => alert("Please Login")}>
            <ButtonAdd Name="Add to Cart"></ButtonAdd>
          </ButtonDiv>
        )}
      </Wrapper>
    </Cont>
  );
}

const ImgDiv = styled.div`
  height: auto;
  width: 100%;
  @media (max-width: 480px) {
    margin-top: 4rem;
  }
`;

const ButtonDiv = styled.div`
  padding: 1em 3em;
  @media (max-width: 480px) {
    padding: 1em;
  }
`;
const Info = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.4em 3em;
  @media (max-width: 480px) {
    padding: 0.5em 0;
  }
`;
const Price = styled.h5`
  font-size: 1.4em;
  padding: 1.5em 2.4em 1em;
  @media (max-width: 480px) {
    padding: 0.5em 0;
    font-size: 21px;
  }
`;

const Image = styled.img`
  background-color: rgba(200, 200, 200, 0.5);
  object-fit: fill;
  width: 100%;
  height: 227px;
`;

const Wrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 514px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: -227px;
  @media (max-width: 480px) {
    position: static;
    top: none;
    height: auto;
    border: none;
  }
`;

const Cont = styled.div`
  width: 370px;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
