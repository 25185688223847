import React, { useEffect } from "react";
import Styled from "styled-components";
import { useState } from "react";
import {
  Wrapper,
  Section,
  Title,
  CompanyName,
  Details,
  DetailSection,
  DetailTitle,
  DetailInfo,
  SkillButton,
  SkillsSection,
  ViewDetailsSection,
  LinkS,
} from "./InternshipCardElements";

export default function InternshipCard({ internship }) {
  const {
    id,
    title,
    employer,
    startDate,
    duration,
    stipendPerMonth,
    tags,
    location,
    isActive,
  } = internship;

  const [windowSize, setWindowSize] = useState(
    window.innerWidth > 500 ? true : false
  );

  window.addEventListener("resize", function () {
    if (window.innerWidth >= 500) {
      setWindowSize(true);
    } else {
      setWindowSize(false);
    }
  });

  const skills = tags;
  return (
    <Wrapper>
      {windowSize === true && (
        <LinkS
          to={`/internships/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Title>
            {title}
            {isActive === false ? <Expired>Missed it</Expired> : null}
          </Title>
          <Section>
            <CompanyName>{employer.name}</CompanyName>
            <Details>
              <DetailSection>
                <DetailTitle>Start Date</DetailTitle>
                <DetailInfo>{startDate}</DetailInfo>
              </DetailSection>
              <DetailSection>
                <DetailTitle>Duration</DetailTitle>
                {duration ? (
                  <DetailInfo>{duration.months} months</DetailInfo>
                ) : null}
              </DetailSection>
              <DetailSection>
                <DetailTitle>Stipend</DetailTitle>
                <DetailInfo>Rs.{stipendPerMonth} p.m</DetailInfo>
              </DetailSection>
              <DetailSection>
                <DetailTitle>Location</DetailTitle>
                <DetailInfo>{location ? location.name : null}</DetailInfo>
              </DetailSection>
            </Details>
            {skills != null ? (
              <SkillsSection>
                <DetailTitle>Skills required : </DetailTitle>
                {skills.map((skill, index) => (
                  <SkillButton key={index}>{skill.name}</SkillButton>
                ))}
              </SkillsSection>
            ) : null}
            <ViewDetailsSection>
              <LinkS to={`/internships/${id}`}>
                View Detail&#160;&#160;&#62;
              </LinkS>
            </ViewDetailsSection>
          </Section>
        </LinkS>
      )}
      {windowSize === false && (
        <LinkS
          to={`/internships/${id}`}
          target="_self"
          rel="noopener noreferrer"
        >
          <Title>
            {title}
            {isActive === false ? <Expired>Missed it</Expired> : null}
          </Title>
          <Section>
            <CompanyName>{employer.name}</CompanyName>
            <Details>
              <DetailSection>
                <DetailTitle>Start Date</DetailTitle>
                <DetailInfo>{startDate}</DetailInfo>
              </DetailSection>
              <DetailSection>
                <DetailTitle>Duration</DetailTitle>
                {duration ? (
                  <DetailInfo>{duration.months} months</DetailInfo>
                ) : null}
              </DetailSection>
              <DetailSection>
                <DetailTitle>Stipend</DetailTitle>
                <DetailInfo>Rs.{stipendPerMonth} p.m</DetailInfo>
              </DetailSection>
              <DetailSection>
                <DetailTitle>Location</DetailTitle>
                <DetailInfo>{location ? location.name : null}</DetailInfo>
              </DetailSection>
            </Details>
            {skills != null ? (
              <SkillsSection>
                <DetailTitle>Skills required : </DetailTitle>
                {skills.map((skill, index) => (
                  <SkillButton key={index}>{skill.name}</SkillButton>
                ))}
              </SkillsSection>
            ) : null}
            <ViewDetailsSection>
              <LinkS to={`/internships/${id}`}>
                View Detail&#160;&#160;&#62;
              </LinkS>
            </ViewDetailsSection>
          </Section>
        </LinkS>
      )}
    </Wrapper>
  );
}

export const Expired = Styled.div`
    position: 'absolute';
	height: 2rem;
	padding-top: .2rem;
	width: 6rem;
	text-align:center;
	background-color: red;
	color: #fff;
	top: 0;
	right: 0;
`;

InternshipCard.defaultProps = {
  internship: {
    role: "Product Manager",
    author: {
      username: "Company Name",
    },
    start_date: "Immediately",
    duration: {},
    stipend: 6000,
    location: {
      name: "Pune",
    },
    tags: ["skill1", "skill2", "skill3"],
  },
};
