import React, { useState, useEffect } from "react";
import {
  InterWrapper,
  Title,
  ButtonWrapper,
} from "../../Internships/InternshipInfoElements";
import axios from "axios";
import Button from "../../../Components/Button";
import styled from "styled-components";
import ResumePopup from "../ResumePopup";

const url = process.env.REACT_APP_BASE_URL;

export default function AddressInfo({ Internship }) {
  const USER_ID = localStorage.getItem("setugramuser");
  const [getuserdetail, setgetUserDetail] = useState([]);
  const [applynowisopen, setApplyNowIsOpen] = useState(false);

  const handleClose = () => {
    setApplyNowIsOpen(false);
  };

  const [setaddress, setSetAddress] = useState("");
  const [seta, setSetA] = useState("");
  const [setb, setSetB] = useState("");
  const [setc, setSetC] = useState("");
  const [setd, setSetD] = useState("");
  const [updatebutton, setUpdateButton] = useState(false);

  useEffect(() => {
    fetch(`${url}/users/${USER_ID}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((getdetails) => {
        setgetUserDetail(getdetails);
        setSetAddress(getdetails.address);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [applynowisopen]);

  const updateForm = () => {
    if (seta !== "" && setb !== "" && setc !== "" && setd !== "") {
      axios
        .put(`${url}/users/${USER_ID}`, {
          address: `${seta}__${setb}__${setc}__${setd}`,
        })
        .then((response) => {
          alert("Your address is been updated !");
        })
        .catch((error) => {
          alert("Please try again after some time !");
        });
    } else {
      alert("Please All field is required !");
    }
  };

  let AddressT = "";

  if (setaddress != null && setaddress.length !== 0) {
    AddressT = getuserdetail.address;
  }

  let a = AddressT.split("__")[0];
  let b = AddressT.split("__")[1];
  let c = AddressT.split("__")[2];
  let d = AddressT.split("__")[3];

  return (
    <>
      <InterWrapper>
        <Title>Address </Title>
        {getuserdetail && (
          <div style={{ marginTop: "2rem" }}>
            <label htmlFor="fInput">Address line 1</label>
            <br />
            <input
              defaultValue={a}
              setSetA={a}
              onChange={(e) => {
                setSetA(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">Address line 2</label>
            <br />
            <input
              defaultValue={b}
              setSetB={b}
              onChange={(e) => {
                setSetB(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">Pin code</label>
            <br />
            <input
              defaultValue={c}
              setSetC={c}
              onChange={(e) => {
                setSetC(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <label htmlFor="fInput">State</label>
            <br />
            <input
              defaultValue={d}
              setSetD={d}
              onChange={(e) => {
                setSetD(e.target.value);
                setUpdateButton(true);
              }}
              id="textarea"
              type="text"
            ></input>
            <br />
            <div
              className="appId-subcon4 row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* {
                                AddressT === "" &&
                                <div>
                                    <ButtonWrapper onClick={() => updateForm()}>
                                        <Button Name='Add Address' />
                                    </ButtonWrapper>
                                </div>
                            } */}
            </div>
          </div>
        )}
        {updatebutton === true && (
          <ButtonWrapper onClick={() => updateForm()}>
            <Button Name="Update Address" />
          </ButtonWrapper>
        )}
      </InterWrapper>
      <ResumePopup
        addAddressIsOpen3={applynowisopen}
        handleClose3={handleClose}
        internshipid={Internship.id}
      ></ResumePopup>
    </>
  );
}

const ButtonDiv = styled.button`
  display: flex;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  margin-left: 0.5rem;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.2em;
  background-color: #fff56c;
`;
