import Styled from "styled-components";
export const InterWrapper = Styled.section`
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 1em;
    @media (max-width: 480px) {
        border: none;
        padding: .5em;
        font-size: 14px;
    }
`;
export const DetailTitle = Styled.h5`
    font-size: 1.5em;
    line-height: 1.4em;
    font-weight: 500;
    @media (max-width: 480px) {
        font-size: 1.1em;
    }
`;

export const ButtonWrapper = Styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1em;
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const DetailSection = Styled.section`

`;
export const Details = Styled.div`
    display: flex;
    justify-content: space-around;
    padding-right: 1em;
    margin-top: 1.5em;
    @media (max-width: 480px) {
        padding: 0 .5em;
        justify-content: space-between;
    }
`;
export const DetailInfo = Styled.p`
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.3em;
    margin-top: .5em;
    line-height: 1.4em;
    @media (max-width: 480px) {
        font-size: 1em;
    }
`;
export const DetailInfoD = Styled.p`
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.3em;
    margin: .5em .5em;
    line-height: 1.4em;
    @media (max-width: 480px) {
        font-size: 1em;
    }
`;
export const Author = Styled.h4`
    color: rgba(0, 0, 0, 0.51); 
    font-size: 1.4em;
    font-weight: 400;
    @media (max-width: 480px) {
        font-size: 1.2em;
        margin-top: .3em;
        padding-left: .6em;
    }
`;
export const Title = Styled.h2`
    font-size: 2em;
    @media (max-width: 480px) {
        background-color: #FFF56C;
        font-size: 1.51em;
        padding: 0.2em 0.5em;
    }
`;
