import React, { useState, useEffect } from "react";
import CourseCard from "../../../Components/shared/CourseCard";
import Styled from "styled-components";
import CoursesServices from "../../../ApiServices/courses";

export default function CoursesSection({
  searchtags,
  duration,
  category,
  maxprice,
  homecategory,
}) {
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(0);
  const numPerPage = 16;

  const fetch = () => {
    CoursesServices.getAllTopCourses(
      page,
      numPerPage,
      searchtags,
      duration,
      category,
      maxprice,
      homecategory
    )
      .then((initialProducts) => {
        setCourses(initialProducts.data);
      })
      .catch((err) => {});
  };

  useEffect(fetch, [page, searchtags, duration, category, maxprice]);

  const handleNextPage = (e) => {
    const newPage = page + numPerPage;
    setPage(newPage);
  };
  const handlePrevPage = (e) => {
    const newPage = page - numPerPage;
    setPage(newPage);
  };
  return (
    <Wrapper>
      <CoursesWrapper>
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </CoursesWrapper>
      <Pagination>
        {page === 0 && <div />}
        {page !== 0 && <Button onClick={() => handlePrevPage()}>Prev</Button>}
        {courses.length === 16 && (
          <Button onClick={() => handleNextPage()}>Next</Button>
        )}
        {courses.length !== 16 && <div />}
        {/* <Button onClick={() => handlePrevPage()}>Prev</Button>
				<Button onClick={() => handleNextPage()}>Next</Button> */}
      </Pagination>
    </Wrapper>
  );
}
//
const Pagination = Styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = Styled.button`
    padding: .4em 1em;
    outline: none;
    border: none;
    color: #fff;
    background-color: #000;
    font-size: 21px;
    @media (max-width: 480px) {
        font-size: 16px;
        padding: .2em .8em;
    }
`;

const Wrapper = Styled.section`
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    flex: 3.3;
    @media (max-width: 480px) {
        font-size: 14px;
    }
    font-size: 14px;
`;
const CoursesWrapper = Styled.section`
    justify-content: left;
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
`;
