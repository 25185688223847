import React, { useState, useEffect } from "react";
import internshipServices from "../../ApiServices/internships";
import Filters from "./Filters";
// import FiltersM from './FilterM';
import Styled from "styled-components";
import InternshipCard from "./InternshipCard";
import IntershipSearchBar from "../../Components/shared/InternshipSearchBar";
import { useParams } from "react-router";

export default function InternshipsAll() {
  const params = useParams();
  const homecategory = params.categoryname;
  const homelocation = params.location;
  const [Internships, setInternships] = useState([]);
  const [searchtags, setSearchTags] = useState(
    localStorage.getItem("internshipchoose")
      ? JSON.parse(localStorage.getItem("internshipchoose"))
      : []
  );
  const [searchArray, setSearchArrray] = useState(
    localStorage.getItem("internshiparray")
      ? JSON.parse(localStorage.getItem("internshiparray"))
      : []
  );
  const [page, setPage] = useState(0);
  const [location, setlocation] = useState();
  const [minstipend, setminstipend] = useState();
  const [maxduration, setmaxduration] = useState();
  const [category, setcategory] = useState();
  const numPerPage = 5;

  const hook = () => {
    internshipServices
      .getAllInternships(
        page,
        numPerPage,
        searchtags,
        location,
        minstipend,
        maxduration,
        category,
        homecategory,
        homelocation
      )
      .then((initialInternships) => {
        setInternships(initialInternships.data);
      })
      .catch((err) => {});
  };

  useEffect(hook, [
    page,
    searchtags,
    location,
    minstipend,
    maxduration,
    category,
  ]);

  const handleNextPage = (e) => {
    const newPage = page + numPerPage;
    setPage(newPage);
  };

  const handlePrevPage = (e) => {
    // if (page>=1){
    // 	const newPage = page - numPerPage;
    // 	setPage(newPage);
    // }

    // else{
    // }
    const newPage = page - numPerPage;
    setPage(newPage);
  };

  return (
    <Container>
      <IntershipSearchBar
        setSearchTags={setSearchTags}
        deafaultsearch={searchArray}
      />
      <Title>Career</Title>
      <SubTitle>
        Find and apply for the best suited Internships/Jobs for free
      </SubTitle>
      {/* <FiltersM homecategory={homecategory} homelocation={homelocation} setlocation={setlocation} setminstipend={setminstipend} setmaxduration={setmaxduration} setcategory={setcategory}/> */}
      <InternContainer>
        <Filters
          homecategory={homecategory}
          homelocation={homelocation}
          setlocation={setlocation}
          setminstipend={setminstipend}
          setmaxduration={setmaxduration}
          setcategory={setcategory}
        />
        <Wrapper>
          {Internships.length === 0 && (
            <InternContainerNo>No Internship/Jobs</InternContainerNo>
          )}
          {Internships.map((internship, index) => (
            <InternshipCard key={index} internship={internship} />
          ))}
          <Pagination>
            {page === 0 && <div />}
            {page !== 0 && (
              <Button onClick={() => handlePrevPage()}>Prev</Button>
            )}
            {Internships.length === 5 && (
              <Button onClick={() => handleNextPage()}>Next</Button>
            )}
            {Internships.length !== 5 && <div />}
            {/* <Button onClick={() => handleNextPage()}>Next</Button> */}
          </Pagination>
        </Wrapper>
      </InternContainer>
    </Container>
  );
}

const Pagination = Styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = Styled.button`
    padding: .4em 1em;
    outline: none;
    border: none;
    color: #fff;
    background-color: #000;
    font-size: 21px;
    @media (max-width: 480px) {
        font-size: 16px;
        padding: .2em 1.8em;
		margin: 0 15px;
    }
`;

const InternContainer = Styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1em;
    padding-top: 1em;
    position: relative;
`;

const Container = Styled.div`
    margin-top: 5.5em;
    @media (min-width: 1440px) {
		width: 1440px;
		margin: 5.5em auto;
	}
    @media (min-width: 1080px) {
        width: 1080px;
        margin: 5.5em auto;
    }
    @media(max-width: 480px) {
        width: auto;
        font-size: 14px;
		margin: 5.5em 0.5em;
    }
`;

const SubTitle = Styled.div`
    margin-top: .5em;
    @media(max-width: 480px) {
        margin: 0;
    }
`;

const Title = Styled.h2`
    font-size: 2em;
    margin-top: .5em;
    @media(max-width: 480px) {
        font-size: 1.55em;
        font-weight: 500;
        margin: 0;
    }
`;

const Wrapper = Styled.div`
    flex: 2.3;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
	width: 96.226%;
`;
const InternContainerNo = Styled.div`
    display: flex;
    justify-content: center;
	align-items: center;
	font-size: 1.55em;
    font-weight: 500;
    gap: 1em;
    padding: 5em;
    position: relative;
`;
