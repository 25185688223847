import React, { useState, useEffect } from "react";
import { Wrapper } from "./FilterElements";
import styled from "styled-components";
import CoursesSlider from "../../../Components/CoursesSlider";
const url = process.env.REACT_APP_BASE_URL;

export default function Filters({
  setcategory,
  setduration,
  setMaxPrice,
  homecategory,
}) {
  const [getduration, setGetDuration] = useState([]);
  const [getcategories, setGetCategories] = useState([]);

  useEffect(() => {
    fetch(`${url}/course-categories`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialduration) => {
        setGetCategories(initialduration);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/z-course-durations`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialcategories) => {
        setGetDuration(initialcategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Wrapper>
      <Container>
        <Head>Filters</Head>
        <SubContainer1>
          <SubHead>Category</SubHead>
          <SubInputD onChange={(e) => setcategory(e.target.value)}>
            {getcategories.map((item, index) => (
              <option
                key={index}
                value={item.name}
                selected={item.name === homecategory}
              >
                {item.name}
              </option>
            ))}
          </SubInputD>
        </SubContainer1>
        <SubContainer>
          <SubHead>Max Price</SubHead>
          <CoursesSlider color="#0074D9" setMaxPrice={setMaxPrice} />
        </SubContainer>
        <SubContainer2>
          <SubHead>Duration (Weeks)</SubHead>
          <SubInputD onChange={(e) => setduration(e.target.value)}>
            {getduration.map((item, index) => (
              <option key={index} value={item.Months}>
                {item.Months}
              </option>
            ))}
          </SubInputD>
        </SubContainer2>
      </Container>
    </Wrapper>
  );
}

const Container = styled.div`
  width: 310px;
  flex: 1;
  @media (max-width: 480px) {
    display: none;
    display: hidden;
  }
`;

const Head = styled.h3`
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  padding: 20px 44px;
  @media (max-width: 480px) {
  }
`;
// eslint-disable-next-line no-unused-vars
const SubInput = styled.input`
  padding: 5px 0;
  border-radius: 5pxs;
  width: 260px;
  height: 41px;
  @media (max-width: 480px) {
  }
`;
const SubInputD = styled.select`
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  border-radius: 5pxs;
  width: 260px;
  height: 41px;
  @media (max-width: 480px) {
  }
`;
const SubHead = styled.h3`
  font-size: 21px;
  font-weight: 400;
  padding: 0 0 3%;
  @media (max-width: 480px) {
  }
`;
const SubContainer = styled.div`
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;
const SubContainer1 = styled.div`
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;

const SubContainer2 = styled.div`
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;

// const SubContainer3 = styled.div`
// 	padding: 10px 25px;
// 	@media (max-width: 480px) {

// 	}
// `;
