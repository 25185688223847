import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Button from "../../Components/Button";
const url = process.env.REACT_APP_BASE_URL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "visible",
    width: "50%",
    marginTop: "2rem",
    transform: "translate(-50%, -50%)",
    padding: 50,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const ApplyNow = ({ addAddressIsOpen3, handleClose3, internshipid }) => {
  const [tags, setTags] = useState([]);
  const [details, setDetails] = useState([]);
  const [category, setCategory] = useState([]);
  const [previousAppliedInternship, setPreviousAppliedInternship] = useState(
    []
  );
  const [linkedin, setLinkedin] = useState("");
  const [files, setFiles] = useState([]);
  const USER_ID = localStorage.getItem("setugramuser");

  useEffect(() => {
    fetch(`${url}/users/${USER_ID}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setDetails(data);
        setCategory(
          data.skills.map((item) => {
            return item.id;
          })
        );
        setLinkedin(data.linkedInProfile);
        setPreviousAppliedInternship(
          data.appliedInternships.map((item) => {
            return item.id;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/z-tags-internships`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const uploadImage = async () => {
    const formData = new FormData();

    formData.append("files", files[0]);

    if (files.length > 0 && files[0].type === "application/pdf") {
      axios
        .post(`${url}/upload`, formData)
        .then((response) => {
          const imageId = response.data[0].id;
          axios
            .put(`${url}/users/${USER_ID}`, {
              resume: imageId,
              linkedInProfile: linkedin,
              skills: category,
              appliedInternships: [
                ...previousAppliedInternship,
                `${internshipid}`,
              ],
            })
            .then((response) => {
              handleClose3();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .put(`${url}/users/${USER_ID}`, {
          linkedInProfile: linkedin,
          skills: category,
          appliedInternships: [...previousAppliedInternship, `${internshipid}`],
        })
        .then((response) => {
          handleClose3();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Modal
      isOpen={addAddressIsOpen3}
      onRequestClose={handleClose3}
      style={customStyles}
      ariaHideApp={false}
    >
      <span
        className="modal-close hover-pointer"
        onClick={handleClose3}
        style={{ cursor: "pointer" }}
      >
        {/* <CloseIcon />{onChange={(e) => setStipend(e.target.value)}} */}
      </span>
      <div style={{ textAlign: "center" }}>
        <h3>Enter Your Details</h3>
      </div>
      <div style={{ width: "100%" }}>
        <label htmlFor="fInput">Upload Resume (pdf)</label>
        <br />
        <input
          id="textarea"
          type="file"
          onChange={(e) => setFiles(e.target.files)}
          required
        ></input>
        <label htmlFor="fInput">Upload Portfolio (pdf)</label>
        <br />
        <input
          id="textarea"
          type="file"
          onChange={(e) => setFiles(e.target.files)}
          required
        ></input>
        <br />
        <label htmlFor="fInput">Technical Skills</label>
        <br />
        <br />
        <Autocomplete
          defaultValue={details.skills}
          multiple
          id="tags-outlined"
          options={tags}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          onChange={(event, newValue) => {
            setCategory(
              newValue.map((ele) => {
                return ele.id;
              })
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Search ..."
              placeholder="Favorites"
            />
          )}
        />
        <br />
        <label htmlFor="fInput">Linkedin</label>
        <br />
        <input
          id="textarea"
          type="text"
          defaultValue={details.linkedInProfile}
          onChange={(e) => setLinkedin(e.target.value)}
          required
        ></input>
        <br />
      </div>
      <div onClick={() => uploadImage()}>
        <Button style={{ width: "100%" }} Name="Apply Now" />
      </div>
    </Modal>
  );
};

export default ApplyNow;
