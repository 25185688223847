import React, { useState, useEffect } from "react";
import axios from "axios";
import ProfileInfo from "./ProfileInfo";
import {
  Container,
  YellowContainer,
  YellowDiv,
  InternshipContainer,
  InternshipDiv,
  WrapperDiv,
} from "../Internships/InternshipDetailsElements";

const url = process.env.REACT_APP_BASE_URL;

export default function Profile({}) {
  const [profile, setProfile] = useState([]);

  const hook = () => {
    // axios.get(`${url}/z-product-orders/${productid}`)
    // .then((response) => {
    // 	setProfile(response.data);
    // })
    // .then((response) => response);
  };

  useEffect(hook, []);

  return (
    <Container>
      <WrapperDiv>
        <YellowContainer>
          <YellowDiv></YellowDiv>
        </YellowContainer>
        <InternshipContainer>
          <InternshipDiv>
            <ProfileInfo Internship={profile} />
          </InternshipDiv>
        </InternshipContainer>
      </WrapperDiv>
    </Container>
  );
}
