import React, { useState } from "react";
import styled from "styled-components";
import Products from "./Products";
import EcommerceSearchBar from "../../Components/shared/EcommerceSearchBar";

const ECommerce = ({ id }) => {
  const [searchtags, setSearchTags] = useState([]);

  return (
    <Container id={id}>
      <div
        style={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Header1>Coming soon</Header1>
      </div>

      {/* <Wrapper>
				<SubWrapper>
					<EcommerceSearchBar setSearchTags={setSearchTags}/>
				</SubWrapper>
				<YellowWrapper>
				</YellowWrapper>
				<Products searchtags={searchtags}/>
			</Wrapper> */}
    </Container>
  );
};

const Heading = styled.h3`
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
    margin-top: 1em;
  }
  padding-left: 480px;
  font-size: 1.7em;
  font-weight: 400;
  margin-top: 1em;
`;

const Header1 = styled.h2`
  font-size: 2.8em;
  text-align: center;
  font-weight: 400;
  margin: 5em 0;
  color: grey;
  letter-spacing: 0.2em;
`;

const Header = styled.h2`
  font-size: 2.8em;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.2em;
`;

const Wrapper = styled.div`
  padding: 0.7em;
  position: relative;
`;
const SubWrapper = styled.section`
  width: calc(100% - 2em);
  position: absolute;
  top: 4em;
  padding: 1.5em 7.7em;
  @media (max-width: 480px) {
    /* display: none; */
    width: 95%;
    position: absolute;
    top: 4em;
    padding: 0.5em;
  }
`;
const YellowWrapper = styled.section`
  height: 292px;
  border-radius: 0.7em;
  width: calc(100% - 2em);
  position: absolute;
  top: 10em;
  z-index: -10;
  padding-top: 2em;
  background-color: #fff56c;
  @media (max-width: 480px) {
    display: none;
  }
`;

const Container = styled.div`
  position: relative;
  /* height: 150vh; */
`;

export default ECommerce;
