import React from "react";
import { Wrapper, List, ListItem, Title } from "./AboutInfoElements";
export default function AboutInternship({ title, data }) {
  let dataArr;
  if (data) {
    dataArr = data.split("\n\n");
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      <List>
        {dataArr &&
          dataArr.map((item) => {
            let nitem = item.replaceAll("**", "");
            nitem = item.replaceAll("*", "");
            return <ListItem>{nitem}</ListItem>;
          })}
      </List>
    </Wrapper>
  );
}

AboutInternship.defaultProps = {
  data: "",
};
