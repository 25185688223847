import React from "react";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import image from "../../assets/images/1-updated.svg";
import image1 from "../../assets/images/3.svg";
import image2 from "../../assets/images/2.svg";
import "react-multi-carousel/lib/styles.css";
import "../../App.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const categories = [
  {
    image: image,
    sec: "internships",
  },
  {
    image: image1,
    sec: "products",
  },
  {
    image: image2,
    sec: "courses",
  },
];

const Images = () => {
  const history = useHistory();

  return (
    <>
      <Wrapper>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={2000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
        >
          {categories.map((category, index) => {
            return (
              <div
                className={"text-center"}
                style={{ marginLeft: "1rem", cursor: "pointer" }}
                onClick={() => history.push("/" + category.sec)}
              >
                <div className="card shadow-sm" id="imageset">
                  <img id="imageSp" src={category.image} alt="category" />
                </div>
              </div>
            );
          })}
        </Carousel>
      </Wrapper>
    </>
  );
};
export default Images;

const Wrapper = styled.div`
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1%;
  margin-top: 2rem;
  @media (max-width: 480px) {
    width: 95%;
  }
`;
