import styled from "styled-components";
import React, { useState, useEffect } from "react";
const url = process.env.REACT_APP_BASE_URL;

export default function Categories({ handleCategory }) {
  const [getCategories, setgetCategories] = useState([]);

  useEffect(() => {
    fetch(`${url}/z-product-categories`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialLocation) => {
        setgetCategories(initialLocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Wrapper>
      {getCategories.map((category, index) => (
        <Button
          key={index}
          onClick={() => handleCategory(category.name)}
          type="button"
        >
          {category.name}
        </Button>
      ))}
    </Wrapper>
  );
}

const Button = styled.button`
  background-color: #000;
  outline: none;
  border: none;
  color: white;
  font-size: 21px;
  padding: 5px 7px;
  border-radius: 10px;
  font-weight: 400;
  display: inline;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 0 1rem;
  /* width: 100vw; */
  @media (max-width: 800px) {
    overflow: scroll;
  }
`;
