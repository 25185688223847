import React, { useEffect } from "react";
import MessageForm from "./MessageForm";
import styled from "styled-components";
import { useLocation } from "react-router";
export default function Message() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <MessageContainer id="contact">
      <MessageInfo />
      <MessageForm />
    </MessageContainer>
  );
}

const MessageInfo = () => {
  return (
    <Wrapper>
      <Title>Message Us</Title>
      <SubTitles>
        Email us any questions you have and we would be happy to answer all your
        questions and set up an online meeting if required.
      </SubTitles>
      <SubTitles>E-mail: &nbsp;&nbsp;&nbsp;contact@setugram.com</SubTitles>
      <SocialWrapper>
        <SocialLink />
        <SocialLink />
        <SocialLink />
      </SocialWrapper>
    </Wrapper>
  );
};

const MessageContainer = styled.div`
  background: #fff56c;
  display: flex;
  padding: 5em;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 2em;
  }
`;

const SocialLink = () => {
  return <div></div>;
};

const Title = styled.h3`
  font-size: 3em;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 21px;
  }
`;
const SubTitles = styled.p`
  font-size: 1.2em;
  line-height: 1.2em;
  margin-top: 1em;
  margin-bottom: 2em;
  color: rgba(0, 0, 0, 0.51);
  @media (max-width: 480px) {
    text-align: center;
    font-size: 16px;
  }
`;
const P = styled.p`
  margin-top: 0.2em;
  line-height: 1.6em;
  font-size: 1.1em;
`;
const SocialWrapper = styled.div`
  font-size: 1.1em;
  margin-top: 3em;
`;

const Wrapper = styled.div`
  width: 50vw;
  padding: 8em;
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
  }
`;
