import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const url = process.env.REACT_APP_BASE_URL;

export default function SearchBar({ setSearchTags, deafaultsearch }) {
  const [tags, setTags] = useState([]);
  const [choosentag, setChoosenTag] = useState([]);

  if (!localStorage.getItem("internshipchoose")) {
    setSearchTags(choosentag);
  }

  useEffect(() => {
    fetch(`${url}/z-tags-internships`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Wrapper>
      <Autocomplete
        multiple
        defaultValue={deafaultsearch}
        id="tags-outlined"
        options={tags}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        onChange={(event, newValue) => {
          localStorage.removeItem("internshipchoose");
          localStorage.removeItem("internshiparray");
          setChoosenTag(
            newValue.map((ele) => {
              return ele.name;
            })
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Search ..."
            placeholder="Favorites"
          />
        )}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* display: grid; */
  place-content: center;
  position: relative;
  padding: 0 0 30px;
  width: auto;
  @media (max-width: 480px) {
    width: auto;
  }
`;
