import React, { useEffect } from "react";
import "./PrivatePolicy.css";

export default function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="wrapperRP" style={{ scrollBehavior: "smooth" }}>
      <div classaName="page">
        <div classaName="container">
          <div classaName="row">
            <div classaName="col-md-12">
              <p>
                <strong>Refund/Return policy</strong>
              </p>
              <p>
                Thank you for buying a course with us. We want to make sure that
                our customers have an exceptional learning experience online. As
                with any online purchase experience, the below are the terms and
                conditions that govern the Refund Policy. When you buy a course
                on setugram.com you agree to our Privacy Policy, Terms of Use
                and the conditions covered below.
              </p>
              <p>
                <strong>
                  Cancellation &amp; Refunds: Online Training programs
                </strong>
              </p>
              <p>
                We understand that you can change your mind. We know that
                sometimes due to unforeseen circumstances you might not be able
                to join the batch on the pre-decided date. That is why we give
                you the freedom to ask to join the next batch (depending on the
                availability) before 72 hours of course commencement. If the
                rescheduling request is made before 24 hours of commencement of
                the course, there shall be no accommodations made. There will be
                no accommodations of request to re-join a course in case of
                missed sessions partial or fully.&nbsp;
              </p>
              <p>
                <strong>Refunds: Duplicate payment</strong>
              </p>
              <p>
                Refund of the duplicate payment made by the customer will be
                processed via same source (original method of payment) within 7
                to 21 working days after intimation by the customer.
              </p>
              <p>
                <strong>
                  Note: All refunds will be processed within 30 days of approval
                  and validation of refund request.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
