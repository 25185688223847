import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ProductImage from "./product.png";

function ProductCardM({ item }) {
  const [windowSize, setWindowSize] = useState(
    window.innerWidth > 500 ? true : false
  );

  window.addEventListener("resize", function () {
    if (window.innerWidth >= 500) {
      setWindowSize(true);
    } else {
      setWindowSize(false);
    }
  });

  const { id, is_new, name, price, variations } = item;
  let src;
  if (variations === undefined || variations.length === 0) {
    src = "";
  } else {
    src = variations[0].image.url;
  }
  src = `${src}`;
  let toDisplay = "none";
  if (is_new) {
    toDisplay = "flex";
  }
  return (
    <Wrapper>
      {windowSize === true && (
        <LinkS to={`/products/${id}`} target="_blank" rel="noopener noreferrer">
          <CardContainer>
            <IsNewWrapper toDisplay={toDisplay}>
              <IsNewDiv>{is_new ? "NEW" : ""}</IsNewDiv>
            </IsNewWrapper>
            <ImgDiv>
              <CardImage src={src} alt={name}></CardImage>
            </ImgDiv>
            <CardNameDiv>{name}</CardNameDiv>
            <Price>Price: {price}</Price>
          </CardContainer>
        </LinkS>
      )}
      {windowSize === false && (
        <LinkS to={`/products/${id}`} target="_self" rel="noopener noreferrer">
          <CardContainer>
            <IsNewWrapper toDisplay={toDisplay}>
              <IsNewDiv>{is_new ? "NEW" : ""}</IsNewDiv>
            </IsNewWrapper>
            <ImgDiv>
              <CardImage src={src} alt={name}></CardImage>
            </ImgDiv>
            <CardNameDiv>{name}</CardNameDiv>
            <Price>Price: {price}</Price>
          </CardContainer>
        </LinkS>
      )}
    </Wrapper>
  );
}
ProductCardM.defaultProps = {
  item: {
    id: 11111111,
    is_new: false,
    images: [ProductImage],
    name: "Turkish Carpet",
    price: 400,
  },
};
const Wrapper = styled.div`
  margin-bottom: 20px;
  /* margin: 10px; */
  @media (max-width: 480px) {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    /* width: 10.67em; */
  }
`;
const ImgDiv = styled.div`
  width: 100%;
  max-height: 155px;
  @media (max-width: 480px) {
    width: 100%;
    height: 95px;
  }
`;

const CardImage = styled.img`
  /* margin-top: 1em; */
  width: 100%;
  padding: 0.001px;
  height: 100%;
  object-fit: fill;
  @media (max-width: 480px) {
    width: 100%;
    height: 95px;
  }
`;
const Price = styled.p`
  padding-left: 15px;
  font-size: 21px;
  font-weight: 400;
  margin: 0.5em 0 0.5em;
  height: 56px;
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 17px;
    padding: 0 5px;
    margin: 0;
  }
`;
//
const LinkS = styled(Link)`
  text-decoration: none;
  color: #000;
  /* font-size: 1em; */
  @media (max-width: 480px) {
    /* width: 11.7em; */
    /* width: 47.6%; */
    width: 100% !important;
    box-sizing: border-box;
    /* border: 2px solid rgba(0, 0, 0, 0.2); */
  }
`;

const IsNewWrapper = styled.div`
  display: ${(props) => props.toDisplay};
  justify-content: right;
`;

const IsNewDiv = styled.div`
  background-color: #fff56c;
  padding: 7px;
  margin-right: 6px;
  @media (max-width: 480px) {
    font-size: 14px;
    margin-right: 2px;
    padding: 2px;
    margin-top: 4px;
  }
`;

const CardNameDiv = styled.h5`
  margin: 0.5em 0 0 0;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 200px;
  color: #000000;
  padding: 0 15px;
  overflow: hidden;
  font-size: 19px;
  color: #000000;
  @media (max-width: 480px) {
    height: 230px;
    padding: 5px;
    margin: 0;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
  }
`;

const CardContainer = styled.div`
  width: 220px;
  height: 300px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    /* width: 11.7em; */
    width: 100%;
    height: 200px;
    border-radius: 0;
    border: none;
  }
`;

export default ProductCardM;
