import React from "react";
import styled from "styled-components";
import Features from "./Features";
import Images from "./Images";
import Services from "./Services";
import Achievement from "./Achievement";
import WhoWeAre from "./WhoWeAre";
import Message from "./Message";
import Search from "./Search";
export default function home() {
  return (
    <HomeContainer>
      <Images />
      <Search />
      <InnerWrapper>
        <Features />
        <Services />
      </InnerWrapper>
      <Achievement />
      <WhoWeAre />
      <Message />
    </HomeContainer>
  );
}

const InnerWrapper = styled.div`
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 0 auto;
  }
`;

const HomeContainer = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 4em;
  @media (max-width: 480px) {
    font-size: 12px;
    padding-top: 3em;
  }
`;
