import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./index.css";
import {
  Container,
  YellowWrapper,
  InfoWrapper,
  Title,
  Info,
  MiddleSection,
  BenefitsWrapper,
} from "./CourseDetailsElements";
import PopularCourses from "../../../Components/shared/PopularCoursesList";
// import BenefitCard from './BenefitCard';
import PurchaseSection from "./PurchaseSection";
import Readmore from "./Readmore";
import CoursesServices from "../../../ApiServices/courses";
import ReactMarkdown from "react-markdown";
import { FaPlayCircle } from "react-icons/fa";
import { IoMdMedal } from "react-icons/io";
import { AiFillSafetyCertificate } from "react-icons/ai";

export default function CourseDetails({ match }) {
  const id = match.params.id;
  const [course, setCourse] = useState({});

  const [ModalIsOpen, setModalIsOpen] = useState(false);

  const fetch = () => {
    CoursesServices.getCourseById(id)
      .then((initialCourse) => {
        setCourse(initialCourse.data);
      })
      .catch((err) => {});
  };

  useEffect(fetch, [id]);

  const { name, description, courseHighlights } = course;

  return (
    <Container>
      <YellowWrapper>
        <InfoWrapper>
          <Title>{name}</Title>
          <Info>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Info>
          <p style={{ cursor: "pointer" }} onClick={() => setModalIsOpen(true)}>
            read more ->
          </p>
        </InfoWrapper>
      </YellowWrapper>
      <MiddleSection>
        <BenefitsWrapper>
          <BenefitCard>
            <DivI>
              <FaPlayCircle />
            </DivI>
            <DivH>{courseHighlights ? courseHighlights.Highlight1 : null}</DivH>
          </BenefitCard>
          <BenefitCard>
            <DivI>
              <AiFillSafetyCertificate />
            </DivI>
            <DivH>{courseHighlights ? courseHighlights.Highlight2 : null}</DivH>
          </BenefitCard>
          <BenefitCard>
            <DivI>
              <IoMdMedal />
            </DivI>
            <DivH>{courseHighlights ? courseHighlights.Highlight3 : null}</DivH>
          </BenefitCard>
        </BenefitsWrapper>
        <PurchaseSection course={course} />
      </MiddleSection>
      <PopularCourses title="Popular Courses" />
      <Readmore
        ModalIsOpen={ModalIsOpen}
        setModalIsOpen={setModalIsOpen}
        name={name}
        data={description}
      />
    </Container>
  );
}

CourseDetails.defaultProps = {
  course: {
    name: "Course Name",
    info: "Ac ac eleifend sed feugiat. Dolor, pretium in sed consectetur placerat vulputate. Ac ac eleifend sed feugiat. Dolor, pretium in sed",
  },
};

const DivI = styled.div`
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  @media (max-width: 480px) {
    display: none;
    font-size: 40px;
  }
`;

const DivH = styled.p`
  height: 30%;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  padding: 0 10px 10px 10px;
  line-height: 25px;
  @media (max-width: 480px) {
    height: 20% !important;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }
`;
const BenefitCard = styled.div`
  max-width: 232px;
  min-height: 221px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  flex: auto;
  min-width: 90px;
  /* min-height: 108px; */
  background-color: #fff;
  @media (max-width: 480px) {
    max-width: 102px !important;
    max-height: 101px !important;
    min-height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
