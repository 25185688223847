import React from "react";
import { Link } from "react-router-dom";

const Failure = () => {
  return (
    <>
      <body className="bg-blue-100" style={{ height: "100vh" }}>
        <main
          className="h-screen flex justify-center items-center"
          style={{
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
            marginTop: "20vh",
          }}
        >
          <div className="bg-white p-10 md:p-20 rounded">
            <div className="text-center">
              <a href="https://imgbb.com/">
                <img
                  src="https://i.ibb.co/KGvmVnm/global-hint-failure-595796.png"
                  style={{
                    height: "10rem",
                    width: "10rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  alt="global-hint-failure-595796"
                />
              </a>

              <h1 className="mt-3 text-4xl font-bold" style={{ color: "red" }}>
                Payment Failed
              </h1>
              <div className="text-center">
                <span>Your Payment was not Successfully processed</span>
                <br />
                <Link to="/">
                  <a className="font-bold" style={{ color: "red" }}>
                    Back to Home
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </body>
    </>
  );
};

export default Failure;
