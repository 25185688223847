// Internship category card.

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
export default function LocationIndex({ CardTitle, link, imageLink }) {
  const History = useHistory();

  return (
    <CardContainer
      onClick={() => History.push(`/internshiplocation/${CardTitle}`)}
    >
      <CardImage src={imageLink} alt="Skill Category Image"></CardImage>
      <Title>
        <H5>{CardTitle}</H5>
      </Title>
    </CardContainer>
  );
}

const CardImage = styled.img`
  ${"" /* margin-top: 1em; */}
  width: 100%;
  height: 70% !important;
  border-radius: 10px;
  object-fit: cover;
  @media (max-width: 480px) {
    min-height: 50px;
    min-width: 50px;
  }
`;

const Title = styled.div`
  margin: 0;
  display: flex;
  height: 30%;
  text-align: center;
  align-items: center !important;
  /* margin-bottom: 0.5em; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
const H5 = styled.div`
  text-transform: capitalize;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
  padding: 0 5px;
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
const CardContainer = styled.div`
  cursor: pointer;
  width: 195px;
  height: 198px;
  background: #ffffff;
  transition: top ease 0.5s;
  position: relative;
  margin-top: 2rem;
  top: 0;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  &:hover {
    top: -10px;
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
  }
  @media (max-width: 480px) {
    min-width: 122px;
    max-height: 120px;
    flex: auto;
    border-radius: 0;
    max-width: 122px;
    border-radius: 10px;
  }
`;
