import React from "react";
import styled from "styled-components";
import ProductSlider from "../../Components/ProductSlider";

export default function Filter({ setMaxPrice }) {
  const [checked, setChecked] = React.useState(true);
  return (
    <Container>
      <Head>Filters</Head>
      <SubContainer>
        <SubHead>Max Price</SubHead>
        <ProductSlider color="#0074D9" setMaxPrice={setMaxPrice} />
      </SubContainer>
    </Container>
  );
}

const Container = styled.section`
  min-width: 264px;
  max-height: 930px;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.44);
  -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.44);
  border-radius: 10px;
  box-sizing: border-box;
  @media (max-width: 480px) {
    display: none;
  }
`;

// const Container = styled.div`
// 	width: 310px;
// 	flex: 1;
// 	@media (max-width: 480px) {
// 		display: none;
// 		display: hidden;
// 	}
// `;

const Head = styled.h3`
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  padding: 24px 108px 44px;
  @media (max-width: 480px) {
  }
`;
// eslint-disable-next-line no-unused-vars
const SubInput = styled.input`
  padding: 5px 0;
  width: 100%;
  height: 41px;
  @media (max-width: 480px) {
  }
`;

const SubInputD = styled.select`
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  width: 100%;
  border-radius: 5pxs;
  height: 41px;
  @media (max-width: 480px) {
  }
`;
const Checkinput = styled.input`
  margin: 0 20px 5px;
  width: 20px;
  border-radius: 5px;
  height: 41px;
  @media (max-width: 480px) {
  }
`;

const SubHead = styled.h3`
  font-size: 21px;
  font-weight: 400;
  padding: 0 0 3%;
  @media (max-width: 480px) {
  }
`;
const SubContainer = styled.div`
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;
const CSubContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 25px;
  @media (max-width: 480px) {
  }
`;
