import React from "react";
import SectionTitle from "../sectionTitle";
import Title from "../../../../Components/Title";
import CoursesList from "./CoursesList";
import styled from "styled-components";
export default function index() {
  const title = "Courses";
  const link = "/courses";
  return (
    <Wrapper>
      <Title
        titleText="Setugram Learning"
        subTitleText="Enrol with us to kickstart your career"
        textSide="left"
      />
      <SectionTitle title={title} link={link} apply={"Learn Now"} />
      <CoursesList />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media (min-width: 1440px) {
    margin: 0 auto;
    margin-top: 4em;
  }
  margin-top: 4em;
`;
