import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import Auth from "../ApiServices/Auth";
export default function Signup() {
  const [Username, setUsername] = useState();
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [redirect, setRedirect] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const user = {
    username: Username,
    email: Email,
    password: Password,
  };
  const handleSignup = () => {
    Auth.Signup(user)
      .then()
      .catch((err) => {});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignup();
    setRedirect(true);
  };
  if (redirect === true) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Label>Sign Up</Label>
        <FormField>
          <InputLabel>Username</InputLabel>
          <br />
          <InputField
            type="text"
            placeholder="Enter your Username"
            value={Username}
            onChange={handleUsernameChange}
          ></InputField>
        </FormField>
        <FormField>
          <InputLabel>E-mail</InputLabel>
          <br />
          <InputField
            type="email"
            placeholder="Enter your email address"
            value={Email}
            onChange={handleEmailChange}
          ></InputField>
        </FormField>
        <FormField>
          <InputLabel>Password</InputLabel>
          <br />
          <InputField
            type="password"
            placeholder="Enter your password"
            value={Password}
            onChange={handlePasswordChange}
          ></InputField>
        </FormField>
        <ButtonWrapper>
          <Button type="submit">Sign Up</Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
}

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 2em;
`;

const ButtonWrapper = styled.div``;

const InputField = styled.input`
  width: 100%;
  padding: 1em;
  margin-top: 0.8em;
  border-radius: 10px;
`;

const InputLabel = styled.label`
  font-size: 1.3em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 1.5em;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.9em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const Form = styled.form`
  text-align: center;
`;

const Container = styled.div`
  width: 526px;
  padding: 2em;
  border: black 1px solid;
  margin: 8em auto;
  @media (max-width: 480px) {
    width: 355px;
    height: 478px;
    padding: 3em 2em;
  }
`;
