import React, { useState, useEffect } from "react";
import internshipServices from "../../../ApiServices/internships";
import Styled from "styled-components";
import InternshipCard from "../InternshipCard";

export default function MyInternship() {
  const [Internships, setInternships] = useState([]);
  const [page, setPage] = useState(0);

  const numPerPage = 5;

  const USER_ID = localStorage.getItem("setugramuser");

  const hook = () => {
    internshipServices
      .getInternshipByUserID(USER_ID)
      .then((initialInternships) => {
        setInternships(initialInternships.data.appliedInternships);
      })
      .catch((err) => {});
  };

  useEffect(hook, []);

  const handleNextPage = (e) => {
    const newPage = page + numPerPage;
    setPage(newPage);
  };

  const handlePrevPage = (e) => {
    const newPage = page - numPerPage;
    setPage(newPage);
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>Your Internships</Title>
      </TitleWrapper>
      <InternContainer>
        <Wrapper>
          {Internships.length === 0 && (
            <InternContainerNo>No Internship</InternContainerNo>
          )}
          {Internships.map((internship, index) => (
            <InternshipCard key={index} internship={internship} />
          ))}
          <Pagination>
            {page === 0 && <div />}
            {page !== 0 && (
              <Button onClick={() => handlePrevPage()}>Prev</Button>
            )}
            {Internships.length === 5 && (
              <Button onClick={() => handleNextPage()}>Next</Button>
            )}
            {Internships.length !== 5 && <div />}
          </Pagination>
        </Wrapper>
      </InternContainer>
    </Container>
  );
}

const TitleWrapper = Styled.div`
    background-color: #FFF56C;
    height: 102px;
	width: 100%;
    padding: 1.7em 2em;
    padding-top: 3rem;
    @media (max-width: 480px) {
        height: 74px;
        padding-top: 40px;
        padding-left: .5em;
        padding-bottom: 1.5em;
    }
`;

const Pagination = Styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = Styled.button`
    padding: .4em 1em;
    outline: none;
    border: none;
    color: #fff;
    background-color: #000;
    font-size: 21px;
    @media (max-width: 480px) {
        font-size: 16px;
        padding: .2em 1.8em;
		margin: 0 15px;
    }
`;

const InternContainer = Styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1em;
    padding-top: 1em;
    position: relative;
`;

const Container = Styled.div`
    margin-top: 5.5em;
    @media (min-width: 1440px) {
		width: 1440px;
		margin: 5.5em auto;
	}
    @media (min-width: 1080px) {
        width: 1080px;
        margin: 5.5em auto;
    }
    @media(max-width: 480px) {
        width: auto;
        font-size: 14px;
		margin: 5.5em 0.5em;
    }
`;

const SubTitle = Styled.div`
    margin-top: .5em;
    @media(max-width: 480px) {
        margin: 0;
    }
`;

const Title = Styled.h2`
    font-size: 2em;
    margin-top: .5em;
    @media(max-width: 480px) {
        font-size: 1.55em;
        font-weight: 500;
        margin: 0;
    }
`;

const Wrapper = Styled.div`
    flex: 2.3;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
	width: 96.226%;
`;
const InternContainerNo = Styled.div`
    display: flex;
    justify-content: center;
	align-items: center;
	font-size: 1.55em;
    font-weight: 500;
    gap: 1em;
    padding: 5em;
    position: relative;
`;
