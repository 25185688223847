import React from "react";
import Internships from "./Internships";
import Learning from "./Courses";
import Products from "./Products";
import styled from "styled-components";
export default function index() {
  return (
    <ServicesContainer>
      <Internships />
      <Products />
      <Learning />
    </ServicesContainer>
  );
}

const ServicesContainer = styled.div`
  margin-top: 5em;
  padding: 0 132px;
  @media (max-width: 480px) {
    padding: 0 1em;
  }
`;
