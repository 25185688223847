import axios from "axios";
// import { min } from 'lodash';

const url = process.env.REACT_APP_BASE_URL;

const getLocations = () => {
  return axios
    .get(`${url}/locations?_start=0&_limit=5`)
    .then((response) => response);
};

const getCategories = () => {
  return axios
    .get(`${url}/z-internship-categories?_start=0&`)
    .then((response) => response);
};

const getInternshipById = (id) => {
  window.scrollTo(0, 0);
  return axios.get(`${url}/internships/${id}`).then((response) => response);
};

const getAllInternships = (
  page,
  num,
  searchtags,
  location,
  minstipend,
  maxduration,
  category,
  homecategory,
  homelocation
) => {
  let query, finalquery;
  finalquery = `_start=${page}&_limit=${num}&_sort=updated_at&`;

  if (searchtags.length > 0) {
    query = searchtags.map((item) => {
      return `tags.name=${item}&`;
    });
  }

  if (location !== undefined) {
    finalquery += `location.name=${location}&`;
  }

  if (minstipend !== undefined) {
    finalquery += `_stipendPerMonth_lte=${minstipend}&`;
  }

  if (maxduration !== undefined) {
    finalquery += `duration_lte=${maxduration}&`;
  }

  if (query !== undefined) {
    finalquery += `${query.toString().replace(/\,/g, "")}`;
  }

  if (category !== undefined) {
    finalquery += `category.name=${category}&`;
  }

  if (homecategory !== undefined) {
    finalquery += `category.name=${homecategory}&`;
  }

  if (homelocation !== undefined) {
    finalquery += `location.name=${homelocation}&`;
  }

  return axios
    .get(`${url}/internships/?${finalquery}`)
    .then((response) => response);
};

const getInternshipByUserID = (id) => {
  window.scrollTo(0, 0);
  return axios.get(`${url}/users/${id}`).then((response) => response);
};

const getInternshipRecm = () => {
  window.scrollTo(0, 0);
  return axios
    .get(`${url}/internships?_start=0&_limit=10`)
    .then((response) => response);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllInternships,
  getInternshipById,
  getInternshipRecm,
  getInternshipByUserID,
  getLocations,
  getCategories,
};
